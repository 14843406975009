.container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    width: 166px;

    .color {
        height: 24px;
        width: 24px;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
            border: 1px solid black;
        }
    }

    .remove {
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--xlight-text-color);
        cursor: pointer;
        border-radius: 4px;

        &:hover {
            color: var(--medium-text-color);
            background: rgba(0,0,0,0.1);
        }
    }
}
