.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .filterHeader {
        padding: 30px 20px 36px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .heading {
            margin-bottom: 20px;
            font-weight: 500;
            color: var(--medium-text-color);
            font-size: 0.95rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .label {
                display: flex;
                padding: 4px 0;

                svg {
                    margin-right: 8px;
                    display: none;
                }

                &.close {
                    cursor: pointer;

                    svg {
                        margin-right: 8px;
                        display: unset;
                    }
                }
            }

            .undo {
                display: flex;
                cursor: pointer;
                color: var(--secondary-theme-color-light);
                font-size: 0.75rem;
                padding: 4px 6px;

                &:hover {
                    color: var(--secondary-theme-color);
                }

                p {
                    margin-left: 6px;
                }
            }
        }
    }

    .filterContainer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 10px 20px;
        min-width: 315px;
        width: 315px;
        background: #f8f8f8;
        overflow-y: auto;

        & > * {
            margin: 10px 0;
        }

        .group {
            display: flex;
            justify-content: space-between;
        }
    }

    .filterApply {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 26px 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        .applyButton {
            width: 100%;
            background: var(--secondary-theme-color);
            border: 1px solid var(--secondary-theme-color);
            opacity: 0.85;

            &:hover {
                opacity: 1;
            }
        }
    }
}
