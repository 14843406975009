.body {
    .header {
        color: var(--medium-text-color);
        font-weight: 700;
        font-size: 20px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -6px;
            height: 1px;
            width: 100%;
            background: rgba(0, 0, 0, 0.1);
        }
    }

    .message {
        color: var(--light-text-color);
        font-size: 13px;
        margin: 20px 0;
    }
}
