.errorContainer {
    height: 100%;
    min-height: 100%;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    overflow: auto;

    .error {
        font-size: 7rem;
        font-weight: bold;
        color: var(--xlight-text-color);
        margin-bottom: 40px;
    }

    .header {
        color: var(--medium-text-color);
        font-weight: 600;
        margin-bottom: 30px;
        font-size: 1.2rem;
    }

    .message {
        color: var(--light-text-color);
        font-size: 0.9rem;
        margin-bottom: 10px;
    }
}

.link {
    a,
    a:visited {
        color: var(--primary-theme-color);
        text-decoration: none;
        opacity: 0.7;
    }

    a:hover {
        opacity: 1;
    }

    svg {
        margin-right: 8px;
    }
}

.container {
    .link {
        margin-bottom: 20px;
    }

    .pretaskContainer {
        font-size: 0.85rem;
        background: #ffffff;
        box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.12);

        h1 {
            color: var(--light-text-color);
        }

        h3 {
            color: var(--medium-text-color);
        }

        h5 {
            color: var(--light-text-color);
            font-size: 1rem;
            margin: 0;
            padding: 0;
        }

        .section {
            margin-top: 20px;
            border-top: 1px solid rgba(0, 0, 0, 0.02);

            .header {
                border-bottom: 1px solid rgba(0, 0, 0, 0.02);
                padding: 14px 0 14px 10px;
                background: #e5e5e5;

                h1,
                h3 {
                    margin: 0;
                }
            }

            .body {
                padding: 16px;

                & > * {
                    margin: 10px 0;
                }

                .input {
                    margin-top: 16px;

                    .value {
                        margin: 3px 0 0 3px;
                        color: var(--light-text-color);
                        font-size: 0.85rem;
                    }
                }

                .inputGroup {
                    display: flex;
                    align-items: center;

                    .input {
                        margin-top: 0;
                    }

                    & > .input:not(:first-child) {
                        margin-left: 14px;
                    }
                }

                .jobNumber {
                    width: 400px;
                }

                .workArea {
                    width: 100%;
                }

                .activity {
                    margin: 30px 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                .newActivity {
                    display: flex;
                    justify-content: center;

                    .newActivityButton {
                        width: 100%;
                        background: #0c9b00;
                        border: none;
                        opacity: 0.9;
                        margin: 0;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                .checklists {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 30px;
                }

                &.signatures {
                    display: flex;
                    flex-direction: column;

                    h3 {
                        margin: 8px 0;
                    }

                    .requiredSignatures {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap-reverse;

                        .employeeSignatures {
                            flex: 2;
                            min-width: 340px;
                            margin-right: 40px;

                            .signatureWarning {
                                height: 30px;
                                color: var(--light-text-color);
                                font-size: 0.75rem;

                                svg {
                                    color: #eed202;
                                    margin-right: 8px;
                                }
                            }

                            .noEmployeeMessage {
                                color: var(--light-text-color);
                                font-size: 0.8rem;
                                margin-left: 2px;
                            }
                        }

                        .supervisorSignatures {
                            flex: 2;
                            min-width: 340px;
                            margin-bottom: 18px;

                            .signatureAddContainer {
                                align-items: flex-start;
                            }

                            @media screen and (min-width: 894px) {
                                .signatureAddContainer {
                                    align-items: flex-end;
                                }
                            }
                        }
                    }

                    .extraSignatures {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .signatureList {
                            margin-bottom: 6px;
                        }
                    }

                    .signatureAddContainer {
                        display: flex;
                        flex-direction: column;

                        .addButton {
                            margin: 0;
                            background: #0c9b00;
                            border: none;
                            opacity: 0.9;

                            &:hover {
                                opacity: 1;
                            }
                        }

                        .supervisorHeader {
                            position: relative;

                            .required {
                                position: absolute;
                                right: calc(100% + 2px);
                                top: -2px;
                                color: #a61a14;
                                font-size: 0.8rem;
                                user-select: none;
                            }
                        }

                        .signatureAddHeader {
                            display: inline-flex;
                            align-items: center;
                            margin-top: 6px;
                            cursor: pointer;

                            &:hover {
                                background: rgba(0, 0, 0, 0.1);
                            }

                            h5 {
                                margin-top: 1px;
                                font-size: 0.85rem;
                            }

                            & > *:first-child {
                                margin-right: 8px;
                            }
                        }

                        .signatureAddBody {
                            display: flex;
                            flex-wrap: wrap;

                            & > * {
                                margin: 6px;
                            }
                        }

                        .addActionContainer {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 2px 4px;
                            cursor: pointer;

                            svg {
                                color: #0c9b00;
                            }
                        }
                    }
                }

                .addButton {
                    margin: 0;
                    background: #0c9b00;
                    border: none;
                    opacity: 0.9;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        .pretaskHeader {
            .section {
                margin-top: 0;
                border-top: none;

                .header {
                    padding: 20px;
                    background: #e5e5e5;

                    h1,
                    h3 {
                        padding: 10px 0;
                    }
                }
            }
        }

        .errors {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding: 10px 30px;
        }

        .actions {
            display: flex;
            justify-content: space-between;
            padding: 20px;
            border-top: 1px solid rgba(0, 0, 0, 0.06);
        }
    }
}

@media screen and (min-width: 750px) {
    .container {
        margin: 20px 40px;
        .section {
            .body {
                margin: 10px 0;

                .workArea {
                    width: 500px;
                }

                .newActivity {
                    .newActivityButton {
                        width: 70%;
                        max-width: 600px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    .container {
        margin: 20px auto;

        .link {
            margin-left: 10px;
        }

        .section {
            .body {
                margin: 10px 0;

                .checklists {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-bottom: 30px;

                    .checklistItem {
                        &:nth-child(even) {
                            margin-left: 30px;
                            flex-basis: calc(50% - 30px);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .container {
        width: 80%;
        max-width: 1100px;

        .section {
            .body {
                padding: 10px 30px 10px 30px;
            }
        }
    }
}

@media screen and (min-width: 1500px) {
    .container {
        width: 60%;
        margin: 40px auto;

        .section {
            .body {
                .workArea {
                    width: 500px;
                }
            }
        }
    }
}
