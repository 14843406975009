.container {
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    //overflow: clip;
    margin: 0 auto;
    display: flex;
    position: relative;

    .loadingContainer {
        margin: auto;
    }

    .errorContainer {
        margin: 40px auto;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        h3 {
            color: var(--medium-text-color);
            margin: 0;
        }

        p {
            color: var(--light-text-color);
        }
    }

    .form {
        width: 100%;

        .bookmarkPanelContainer {
            margin: 0 auto;
            max-width: 100%;
        }
    }

    .submittedContainer {
        width: calc(min(800px, 100%));
        padding: 0 20px;
        height: min-content;
        display: flex;
        flex-direction: column;
        color: var(--medium-text-color);
        gap: 40px;
        margin: 16px auto;

        h3 {
            margin: 0;
        }

        .notificationDisclaimer {
            color: var(--light-text-color);
        }

        .signatureContainer {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    .error {
        text-align: center;
        display: flex;
        flex-direction: column;
        color: var(--medium-text-color);

        & > * {
            margin: 10px 0;
        }

        & > p {
            color: var(--light-text-color);
        }

        a,
        a:visited {
            color: var(--primary-theme-color-light);
        }

        a:hover {
            color: var(--primary-theme-color);
        }
    }

    .errors {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 20px;
    }

    .actions {
        display: flex;
        justify-content: flex-end;
    }
}
