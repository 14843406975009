.container {
    width: 100%;
    min-height: 100%;
    //padding: 10px 0;
    overflow: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .bodyContainer {
        width: 100%;

        .contactsContainer {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 20px;

            & > * {
                flex: 1;
                min-width: 100%;
                max-width: 100%;

                @media only screen and (min-width: 500px){
                    min-width: calc(50% - 8px);
                    max-width: calc(50% - 8px);
                }

                @media only screen and (min-width: 1100px){
                    min-width: calc(33.33% - 12px);
                    max-width: calc(33.33% - 12px);
                }
            }
        }

        .noStepsMessage {
            color: var(--xlight-text-color);
            font-style: italic;
            margin: 20px 0 40px;
            text-align: center;
        }

        .actions {
            width: 100%;

            & > * {
                float: right;
            }
        }
    }
}
