.container {
    border: 1px solid #06294e;
    background: var(--secondary-theme-color);
    color: var(--contrast-medium-text-color);
    font-weight: 600;
    display: inline-flex;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
    font-size: 0.75rem;
    padding: 4px 8px 4px 12px;
    align-items: center;

    .icon {
        margin-right: 8px;
        color: #ffffff;
        display: flex;
        align-items: center;
    }

    .content {
        user-select: none;
        margin-top: 2px;
    }

    .actions {
        display: flex;
        margin-left: 4px;

        .action {
            display: flex;
            margin: 0 0 0 2px;
            opacity: 0.85;
            cursor: pointer;
            padding: 3px 6px;

            &:first-child {
                margin-left: 12px;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}
