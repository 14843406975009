.container {
    padding: 40px 10px 80px;
    overflow: auto;
    width: 100%;

    .form {
        width: calc(min(800px, 100%));
        margin: 0px auto;

        & > * {
            margin: 16px 0;
        }

        .header {
            text-decoration: underline;
        }
    }

    .heading {
        margin-bottom: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        p {
            font-style: italic;
            color: var(--light-text-color);
            font-size: 0.9rem;
        }

        h2 {
            margin: 4px 0;
        }
    }

    .saveButton {
        display: flex;
        justify-content: center;
    }
}
