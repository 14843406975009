.errorContainer {
    height: 100%;
    min-height: 100%;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    overflow: auto;

    .error {
        font-size: 7rem;
        font-weight: bold;
        color: var(--xlight-text-color);
        margin-bottom: 40px;
    }

    .header {
        color: var(--medium-text-color);
        font-weight: 600;
        margin-bottom: 30px;
        font-size: 1.2rem;
    }

    .message {
        color: var(--light-text-color);
        font-size: 0.9rem;
        margin-bottom: 10px;
    }
}