.bubbleContainer {
    display: flex;
    flex-wrap: wrap;

    .bubble {
        margin: 8px 8px 0 0;
        display: flex;
        align-items: center;
        line-height: 0;
        padding: 8px 2px 6px 12px;
        background: var(--primary-theme-color);
        color: var(--contrast-dark-text-color);
        font-weight: bold;
        font-size: .7rem;
        border-radius: 16px;
        height: 24px;

        .bubbleRemove{
            margin-left: 4px;
            color: var(--contrast-xlight-text-color);
            margin-bottom: 1px;
            cursor: pointer;
            padding: 4px 6px;

            &:hover{
                color: var(--contrast-dark-text-color);
            }
        }
    }
}

.actionContainer {
    color: var(--xlight-text-color);
    font-size: 0.8rem;
    margin-top: 18px;

    & > * {
        display: inline-flex;
        cursor: pointer;
        padding: 6px 10px;
        border-radius: 8px;

        &:hover {
            color: var(--medium-text-color);
            background: #f1f1f1;
        }

        & > :first-child{
            margin-right: 4px;
            color: var(--primary-theme-color-light);
        }

        &:focus {
            outline: 1px solid var(--primary-theme-color-light);
        }
    }
}

.customTagContainer {
    display: flex;
    margin-top: 16px;
    width: 300px;

    .customTagIcons {
        display: flex;

        & > * {
            display: flex;
            align-items: center;
            height: 36px;
            padding: 0 8px;
            color: var(--xlight-text-color);
            cursor: pointer;

            &:hover{
                color: var(--primary-theme-color);
            }
        }
    }
}