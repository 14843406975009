.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 3px 8px 2px rgba(0, 0, 0, 0.06);

    /* .gridHeader {
        background: #eeeeee; //#dcdbdb;//#3d3d3d;
        color: var(--medium-text-color);
    } */
}

.currentCertification {
    color: rgba(0, 128, 0, 0.5);
}

.expiringCertification {
    color: rgba(255, 100, 0, 0.5);
}

.expiredCertification {
    color: rgba(255, 0, 0, 0.5);
}
