.navigationWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .navigationBody {
        display: flex;
        width: 100%;
        background: var(--primary-background-color);
        position: relative;
        overflow: auto;
        flex-grow: 1;

        .bodyContainer {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            position: relative;
            overflow: hidden;

            .bodyContent {
                flex-grow: 1;
                overflow: auto;
            }
        }
    }

    .navBar {
        height: 48px;
        min-height: 48px;
        max-height: 48px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: var(--primary-theme-color);
        padding-right: 16px;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1000000000;
        box-shadow: 0 1px 12px 2px rgba(0, 0, 0, 0.1);

        .leftNavGroup {
            display: flex;

            .menuButtonContainer {
                color: var(--contrast-dark-text-color);
                font-size: 1.6rem;
                height: 46px;
                width: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                }

                .menuButton {
                    height: 18px;
                    cursor: pointer;
                }
            }

            .logoContainer {
                display: flex;
                align-items: center;
                padding: 0 10px 0 0;

                .logo {
                    height: 32px;
                }
            }
        }

        .rightNavGroup {
            display: flex;

            .login {
                display: flex;
                align-items: center;
                color: var(--contrast-medium-text-color);
                font-weight: 700;
                font-size: 13px;
                cursor: pointer;
                padding: 0 8px;

                &:hover {
                    color: var(--contrast-dark-text-color);
                }

                .icon {
                    margin-right: 6px;
                }
            }
        }
    }

    .navMenuContainer {
        max-width: 49px;
        overflow: visible;
        z-index: 1000000000;

        .navMenu {
            padding-top: 8px;
            width: 49px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            background: #ffffff;
            overflow: hidden;
            transition: width 0.02s linear;
            box-shadow: 1px 1px 12px 2px rgba(0, 0, 0, 0.1);

            .navItem {
                height: 40px;
                padding: 10px 0;
                width: 240px;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    background: rgba(0, 0, 0, 0.08);
                }

                .navLink {
                    text-decoration: none;
                    color: inherit;
                    width: 100%;
                    height: 100%;
                    padding: 0 9px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    font-size: 13px;

                    .iconContainer {
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        .navIcon {
                            width: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg {
                                font-size: unset;
                                height: 22px;
                                width: 22px;
                                color: #983333;
                            }
                        }
                    }
                }
            }

            .navLogout {
                height: 40px;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    background: rgba(0, 0, 0, 0.08);
                }

                .navLink {
                    text-decoration: none;
                    color: inherit;
                    width: 240px;
                    padding: 0 20px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    cursor: pointer;

                    div {
                        padding-left: 12px;
                        font-size: 13px;
                    }
                }
            }
        }

        .expandedMenu {
            width: 240px;
        }
    }
}

@media only screen and (max-width: 550px) {
    .navigationBody {
        padding-left: 0 !important;
    }

    .navMenu {
        position: absolute;
    }
}
