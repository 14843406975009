@import '@csstools/normalize.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

%base-resets {
    height: 100% !important;
    min-height: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
}

html {
    @extend %base-resets;
}

body {
    margin: 0;
    font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @extend %base-resets;

    --primary-theme-color: #600010;
    --primary-theme-color-light: #a74556;
    --secondary-theme-color: #093a6d;
    --secondary-theme-color-light: #6699cc; //#0070D2;//#306cab;
    --primary-background-color: #f8f8f8;
    --secondary-background-color: #ffffff;
    --dark-text-color: rgba(0, 0, 0, 0.96);
    --medium-text-color: rgba(0, 0, 0, 0.82);
    --light-text-color: rgba(0, 0, 0, 0.66);
    --xlight-text-color: rgba(0, 0, 0, 0.36);
    --contrast-dark-text-color: rgba(255, 255, 255, 0.96);
    --contrast-medium-text-color: rgba(255, 255, 255, 0.86);
    --contrast-light-text-color: rgba(255, 255, 255, 0.76);
    --contrast-xlight-text-color: rgba(255, 255, 255, 0.7);
    --error-text-color: #d91726;
    --success-text-color: #2aca2a;
}

* {
    box-sizing: border-box;
    font-family: 'Roboto', Menlo, Monaco, Consolas, 'Courier New', monospace;
}
*::-webkit-scrollbar {
    width: calc(max(100%, 14px));
    height: calc(max(100%, 14px));
    background: rgba(0, 0, 0, 0.05);
}

*::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border: 3px solid rgba(0, 0, 0, 0);
    border-radius: 8px;
    background-clip: padding-box;
}

*::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
    border: 3px solid rgba(0, 0, 0, 0);
    border-radius: 8px;
    background-clip: padding-box;
}

p {
    padding: 0;
    margin: 0;
}

#root {
    @extend %base-resets;
}

code {
    font-family: 'Roboto', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app {
    position: relative;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
}

#tooltip-root {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000000001;
    top: 0;
    left: 0;
    overflow: hidden;
}

#modal-root {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 1000000000;
}

@media print {
    body {
        height: unset !important;

        * {
            -webkit-print-color-adjust: exact;
        }
    }

    nav {
        display: none !important;
    }

    @page {
        margin: 0 !important;
        padding: 0 !important;
    }
}
@media screen and (max-width: 767px) {
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="password"],
    textarea {
      font-size: 16px!important;
    }
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: var(--xlight-text-color);
    font-size: .9rem;
    pointer-events: none;
    height: 0;
  }