.containerRoot {
    z-index: 1000000000000 !important;
}

.snackbarAnchorOriginBottomRight {
    .dismiss {
        background: transparent !important;
        border: none !important;
        padding: 2px 4px;
        font-size: 10px;
        color: rgba(255, 255, 255, 0.6) !important;
        box-shadow: none;

        &:hover {
            color: rgba(255, 255, 255, 0.9) !important;
            background: transparent !important;
            border: none !important;
            box-shadow: none !important;
        }
    }
}

@media only screen and (min-width: 599.96px) {
    .snackbarAnchorOriginBottomRight {
        right: 40px !important;
        bottom: 24px !important;
    }
}

@media only screen and (max-width: 599.95px) {
    .snackbarAnchorOriginBottomRight {
        right: inherit;
        bottom: inherit;
    }
}
