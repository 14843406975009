.completedAt {
    align-self: center;
    text-align: right;
    padding: 4px 0 0;
    font-size: .8rem;
    font-weight: 400;
    font-style: italic;
    color: var(--light-text-color);
    width: 100%;
}

.stepContainer {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    overflow: hidden;

    .sequence {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        font-weight: bold;
        background: rgba(0,0,0,0.2);
        color: var(--medium-text-color);
        border-right: 1px solid rgba(0, 0, 0, 0.3);
    }

    .checkboxContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 50px;
        max-width: 50px;
        width: 50px;
        border-left: 1px solid rgba(0, 0, 0, 0.3);
        cursor: pointer;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;

        &.completed {
            background: var(--primary-theme-color-light);
            color: #ffffff;
            cursor: unset;
            opacity: .9;
        }

        &.incomplete {
            color: var(--xlight-text-color);
        }

        &.incomplete:hover {
            color: var(--light-text-color);
            background: rgba(0, 0, 0, 0.025);
        }
    }

    .stepBodyContainer {
        display: flex;
        flex-direction: column;
        flex: 1;

        .stepHeader {
            display: flex;
            gap: 4px;
            font-weight: bold;
            padding: 8px;
        }

        .stepBody {
            font-size: 0.8rem;
            border-top: 1px solid rgba(0,0,0,0.2);
            padding: 8px;
        }

        .stepAttachments {
            border-top: 1px solid rgba(0,0,0,0.2);
            padding: 8px;
        }

        .stepComments {
            font-size: 0.8rem;
            color: var(--primary-theme-color-light);
            cursor: pointer;
            border-top: 1px solid rgba(0,0,0,0.2);
            padding: 8px;

            &:hover {
                color: var(--primary-theme-color);
            }

            .addComments, .hideComments {
                display: flex;
                gap: 4px;
            }
        
            .hideComments {
                padding: 4px 0;
            }     
        }
    }
}
