.container {
    margin: 4px 0;
    padding: 8px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.075);

    .row {
        display: flex;
        margin: 4px 0;

        & > :first-child {
            flex: 1;
        }

        .formType {
            font-size: 0.85rem;
            color: var(--light-text-color);
            font-weight: 500;
            padding: 0 4px;
        }

        .deleteContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            color: var(--light-text-color);
            font-size: 0.8rem;

            .delete {
                padding: 0 12px;
                cursor: pointer;

                &:hover {
                    color: var(--dark-text-color);
                }
            }
        }

        .bubbleContainer {
            display: flex;
            flex-wrap: wrap;

            .bubble {
                margin: 8px 8px 0 0;
                display: flex;
                align-items: center;
                line-height: 0;
                padding: 6px 12px;
                background: var(--primary-theme-color);
                color: var(--contrast-dark-text-color);
                font-weight: bold;
                font-size: .7rem;
                border-radius: 16px;
                height: 24px;
            }
        }
    }
}
