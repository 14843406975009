.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    background: var(--secondary-background-color);

    .innerContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 40px;
        font-size: 2rem;

        .iconContainer {
            display: flex;
            align-items: flex-start;
            font-weight: 900;
            color: rgba(0, 0, 0, 0.15);
            writing-mode: vertical-lr;
            font-size: 4em;
            margin-bottom: 10px;
            user-select: none;
        }

        .body {
            display: flex;
            flex-direction: column;
            font-size: 0.9em;

            a,
            a:visited,
            a:link,
            a:hover,
            a:active {
                color: var(--primary-theme-color);
            }

            .content {
                margin-bottom: 40px;

                .header {
                    font-weight: 700;
                    color: var(--dark-text-color);
                    margin-bottom: 6px;
                }

                .subheader {
                    font-size: 0.77em;
                    font-weight: 500;
                    color: var(--light-text-color);
                    margin-bottom: 12px;
                }

                .errorCode {
                    display: flex;
                    gap: 10px;
                    font-size: 0.55em;
                    color: var(--light-text-color);
                }
            }

            .information {
                display: flex;
                flex-direction: column;

                .header {
                    color: var(--medium-text-color);
                    font-weight: 600;
                    font-size: 0.65em;
                }

                .steps {
                    color: var(--light-text-color);
                    margin-bottom: 40px;
                    font-size: 0.55em;
                }

                .contact {
                    font-size: 0.472em;
                    color: var(--medium-text-color);
                }
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .container {
        .innerContainer {
            max-width: 700px;
            flex-direction: row;
        }
    }
}
