.container {
    padding: 2px;
    
    &.row {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        & > * {
            display: inline-flex;
        }
    }

    &.column {
        display: flex;
        flex-direction: column;
    }
}
