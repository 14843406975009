.container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    flex: 1 1 auto;
    padding: 40px 28px;

    .section {
        display: flex;
        flex-direction: column;
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 60px;
        }

        .detailGroup {
            display: flex;
            flex-direction: column;
            margin: 20px 0;

            &:first-child {
                margin: 0 0 20px 0;
            }

            .detailProperty {
                display: flex;
                flex-direction: column;
                flex: 1;

                &:not(:first-child) {
                    margin-left: 20px;
                }

                .detailLabel {
                    color: var(--medium-text-color);
                    font-weight: 600;
                    padding-bottom: 4px;
                }

                .detailValue {
                    color: var(--light-text-color);
                    font-size: 13px;
                    padding-left: 4px;
                }
            }
        }
    }
}
