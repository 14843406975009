.titleContainer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 40px;
    flex: 1;

    .actionContainer {
        display: flex;
        margin-left: 16px;
        margin-top: 2px;

        .action {
            font-size: 0.85rem;
            padding: 6px;
            margin: 2px;
            border-radius: 8px;
            cursor: pointer;
            color: var(--xlight-text-color);

            &:hover {
                color: var(--primary-theme-color-light);
            }
        }
    }
}

.container {
    padding: 0 16px 30px;

    .row {
        padding: 18px 0;

        &.twoColumn {
            display: flex;

            & > * {
                flex: 1;
            }
        }

        .text {
            margin: 4px 0;
            font-size: 0.85rem;
            color: var(--light-text-color);
        }

        .primaryText {
            display: flex;
            margin: 4px 0;
            font-size: 0.8rem;
            color: var(--light-text-color);
            border-bottom: 1px solid rgba(0,0,0,0.05);
            padding: 4px 0;

            .primaryContainer {
                width: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 4px;

                .primary {
                    color: var(--primary-theme-color-light);
                    margin-right: 8px;
                    font-size: .87rem;
                }
            }

            .copyContainer {
                margin-left: 4px;
                color: var(--xlight-text-color);
            }
        }

        .emptyMessageContainer {
            font-size: 0.75rem;
            color: var(--light-text-color);
            padding: 4px 0;
        }

        .chipContainer {
            display: flex;
            flex-wrap: wrap;
            margin: 4px 0 0;

            & > * {
                margin: 2px;
                vertical-align: middle;
            }
        }
    }
}
