.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0 20px;

    .downloadMessage, .errorMessage {
        color: var(--light-text-color);
    }

    .errorIcon {
        font-size: 2rem;
        margin: 20px 0;
        color: var(--primary-theme-color-light);
    }

    .downloadLoader {
        margin: 20px 0;
    }
}