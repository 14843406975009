.loading {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
}

.errorContainer {
    height: 100%;
    min-height: 100%;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    overflow: auto;

    .error {
        font-size: 7rem;
        font-weight: bold;
        color: var(--xlight-text-color);
        margin-bottom: 40px;
    }

    .header {
        color: var(--medium-text-color);
        font-weight: 600;
        margin-bottom: 30px;
        font-size: 1.2rem;
    }

    .message {
        color: var(--light-text-color);
        font-size: 0.9rem;
        margin-bottom: 10px;
    }

    .link {
        a,
        a:visited {
            color: var(--primary-theme-color);
            text-decoration: none;
            opacity: 0.7;
        }

        a:hover {
            opacity: 1;
        }

        svg {
            margin-right: 8px;
        }
    }
}

.container {
    width: 100%;
    min-height: 100%;
    padding: 40px 0 80px;
    position: relative;
    display: flex;
    justify-content: center;

    .save {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 20px;
        padding: 8px 6px 8px 16px;
        font-size: 0.75rem;
        margin: 0 20px;
        border-radius: 4px;
        color: var(--contrast-dark-text-color);
        font-weight: 600;
        background: rgba(0, 0, 0, 0.75);
        z-index: 100;

        .button {
            border-radius: 0;
            border: none;
            box-shadow: none;
            padding: 6px 10px 6px 16px;
            margin-right: 0;
            border-left: 1px solid white;
            background: transparent;
            color: rgba(255, 255, 255, 0.8);

            &:hover {
                background: inherit;
                border: none;
                border-left: 1px solid white !important;
                color: rgba(255, 255, 255, 1);
            }
        }

        .message {
            margin-right: 8px;
        }
    }

    .customize {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 20px;
        max-width: 600px;

        .formType {
            width: 100%;
            display: flex;
        }

        .main {
            display: flex;
            flex-direction: column;
            width: 100%;

            .leftColumn {
                flex: 1;
                display: flex;
                flex-direction: column;
            }

            section {
                width: 100%;

                h3 {
                    color: var(--medium-text-color);
                }

                h5 {
                    font-weight: 500;
                    margin: 10px 0;
                    color: var(--medium-text-color);
                }

                .header {
                    h3 {
                        padding: 3px 0;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    }
                }

                .body {
                    margin-left: 20px;

                    .headerSelect {
                        margin-bottom: 24px;
                    }

                    .noItemsMessage {
                        font-size: 0.75rem;
                        color: var(--light-text-color);
                    }

                    section {
                        margin-bottom: 20px;
                    }

                    .customFields {
                        .customField {
                            margin-bottom: 12px;

                            .card {
                                display: flex;
                                justify-content: space-between;
                                background: #ffffff;
                                border-radius: 6px;
                                padding: 0 4px 0 0;
                                font-size: 0.85rem;
                                box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.1);
                                min-height: 40px;

                                .handle {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    min-height: 100%;
                                    background: var(--primary-theme-color);
                                    color: var(--contrast-xlight-text-color);
                                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                                    width: 34px;
                                    border-top-left-radius: 6px;
                                    border-bottom-left-radius: 6px;

                                    &:hover {
                                        color: var(
                                            --contrast-medium-text-color
                                        );
                                    }
                                }

                                .leftGroup,
                                .rightGroup {
                                    display: flex;
                                    align-items: center;
                                }

                                .leftGroup {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    flex: 1;

                                    & > * {
                                        width: 100%;
                                    }

                                    .header {
                                        display: flex;
                                        align-items: center;
                                        border-bottom: 1px solid
                                            rgba(0, 0, 0, 0.1);

                                        .delete {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            cursor: pointer;
                                            padding: 8px;
                                            color: var(--xlight-text-color);

                                            &:hover {
                                                color: var(--light-text-color);
                                                background: rgba(0, 0, 0, 0.04);
                                                border-radius: 50%;
                                            }
                                        }
                                    }

                                    .row {
                                        display: flex;
                                        padding: 14px 30px;

                                        & > * {
                                            flex: 1;
                                        }
                                    }

                                    .value {
                                        width: 100%;
                                        border: none !important;
                                        outline: none !important;
                                        line-height: 40px;
                                        padding: 0;
                                        font-size: 0.8rem;
                                        padding-left: 10px;
                                        color: var(--medium-text-color);
                                    }
                                }
                            }
                        }
                    }

                    .addCustomButton {
                        display: flex;
                        justify-content: flex-end;
                        margin: 8px 0 0;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 550px) {
    .container {
        .customize {
            margin: 0 40px;
        }
    }
}

@media screen and (min-width: 1000px) {
    .container {
        margin: 80px 0;
        .customize {
            max-width: unset;

            .main {
                flex-direction: row;

                .leftColumn {
                    margin-right: 50px;
                }

                .rightColumn {
                    width: 300px;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .container {
        .customize {
            width: 1000px;

            .leftColumn {
                flex: 1;
            }

            .rightColumn {
                width: 300px;
            }
        }
    }
}
