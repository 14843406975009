.form {
    padding: 0 16px;

    .row {
        padding: 12px 0;

        .emptyMessageContainer {
            font-size: 0.75rem;
            color: var(--light-text-color);
            padding: 4px 0;
        }

        .textInputContainer {
            margin-bottom: 12px;

            & > * {
                margin: 5px 0;
            }
        }

        .chipContainer {
            margin: 10px 0 0;

            & > * {
                margin: 2px;
            }

            .primaryCategoryIcon {
                opacity: 0.1;
                cursor: pointer;

                &:hover:not(.primaryCategory) {
                    opacity: 0.3;
                }

                &.primaryCategory {
                    opacity: 1;
                }
            }
        }

        .actionContainer {
            color: var(--xlight-text-color);
            font-size: 0.8rem;
            margin-top: 4px;

            & > * {
                display: inline-flex;
                cursor: pointer;
                padding: 4px 6px;
                border-radius: 8px;

                &:hover {
                    color: var(--medium-text-color);
                    background: #f1f1f1;
                }

                & > :first-child {
                    margin-right: 4px;
                    color: var(--primary-theme-color-light);
                }
            }
        }
    }
}
