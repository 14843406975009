.form {
    padding: 0 16px;

    .row {
        padding: 12px 0;

        .qualityTagInput {
            margin: 4px 0;
        }
    }
}