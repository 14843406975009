.container {
    display: block;
    width: 100%;
    color: var(--medium-text-color);

    .checklistItem {
        width: 100%;
        max-width: 800px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: -1px;
        margin-left: -1px;
        margin: 10px auto;
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.04);
        background: rgba(0, 0, 0, 0.03);
        page-break-inside: avoid;

        &.readOnly {
            box-shadow: none;
        }

        .item {
            flex-direction: column;
            display: flex;

            .header {
                display: flex;
                font-size: 0.75rem;
                min-height: 45px;

                .label {
                    display: flex;
                    align-items: center;
                    padding: 16px;
                    flex: 1;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                }

                .options {
                    display: flex;
                    font-size: 0.85rem;
                    background: white;
                }
            }

            .body {
                display: flex;
                flex-direction: column;
                padding: 8px;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                background: white;

                .value {
                    color: var(--medium-text-color);
                    font-size: 0.8rem;
                }
            }
        }

        .error {
            border: 1px solid red;
        }
    }
}
