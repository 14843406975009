.container {
    width: 200px;
    padding: 40px 0;
    overflow: auto;
    display: flex;
    gap: 4px;
    flex-direction: column;
    background: var(--secondary-background-color);
    border-radius: 8px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.06);
    position: relative;
    padding: 10px 14px;
    color: var(--medium-text-color);
    overflow: hidden;

    .header {
        display: flex;
        gap: 6px;
        align-items: center;

        .emergencyIcon {
            color: var(--error-text-color);
            font-weight: bold;
            font-size: 1.1rem;
        }

        .headerText {
            font-weight: bold;
            color: var(--medium-text-color);
            font-size: 0.95rem;
            overflow: hidden;
            flex: 1;
        }

        .actions {
            display: flex;
            gap: 8px;
            font-size: 0.75rem;
            color: var(--xlight-text-color);
            cursor: pointer;

            & > * :hover {
                color: var(--medium-text-color);
            }
        }
    }

    .subText {
        color: var(--light-text-color);
        font-size: 0.9rem;
        min-height: 16.5px;

        &.notProvided {
            color: var(--xlight-text-color);
            font-style: italic;
            font-size: 0.8rem;
        }
    }
}
