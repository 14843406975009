.checkboxContainer {
    display: inline-flex;
    align-items: center;

    .nativeCheckbox {
        border: 0;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .customCheckbox {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        transition: all 150ms;
        color: #999;
        cursor: pointer;
        font-size: inherit;
        box-shadow: 1px 1px 12px 2px rgba(0, 0, 0, 0.04);

        &.neutral {
            color: var(--secondary-theme-color);
            opacity: 0.8;
        }

        &.checked {
            color: var(--secondary-theme-color-light);
        }

        &.disabled {
            cursor: unset;
            opacity: 0.5;
        }

        &.disabled.checked {
            color: #272727;
        }
    }

    .nativeCheckbox:focus + .customCheckbox {
        color: var(--secondary-theme-color-light);
    }
}
