.container {
    .ribbon {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 8px;
        padding: 4px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(0, 0, 0, 0.1);
        font-size: 0.85rem;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px 4px 0 0;
        color: var(--xlight-text-color);

        .leftOptions,
        .rightOptions {
            display: flex;
            gap: 3px;

            .item {
                cursor: pointer;
                padding: 4px;
                height: 22px;
                width: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                user-select: contain;

                &.active {
                    color: var(--primary-theme-color);
                    //border: 1px solid var(--primary-theme-color);
                    background: rgba(0, 0, 0, 0.5);
                    color: rgba(255, 255, 255, 0.9);
                }

                &:not(.active):hover {
                    color: var(--dark-text-color);
                    background: rgba(0, 0, 0, 0.075);
                }
            }

            .divider {
                border-right: 1px solid rgba(0, 0, 0, 0.4);
                margin: 0 4px;
            }

            .dropdownMenuItem {
                position: relative;

                .dropdownMenu {
                    position: absolute;
                    top: calc(100% + 8px);
                    left: 50%;
                    transform: translateX(-50%);
                    background: rgba(0, 0, 0, 0.1);
                    padding: 4px;
                    border-radius: 4px;
                    z-index: 100000;
                }
            }
        }
    }

    .bubbleMenu {
        display: flex;
        justify-content: space-between;
        gap: 2px;
        padding: 2px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: #e2e2e2;
        font-size: 0.75rem;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        color: var(--xlight-text-color);

        .item {
            cursor: pointer;
            padding: 4px;
            height: 22px;
            width: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            user-select: contain;

            &.active {
                color: var(--primary-theme-color);
                //border: 1px solid var(--primary-theme-color);
                background: rgba(0, 0, 0, 0.5);
                color: rgba(255, 255, 255, 0.9);
            }

            &:not(.active):hover {
                color: var(--dark-text-color);
                background: rgba(0, 0, 0, 0.075);
            }
        }

        .divider {
            border-right: 1px solid rgba(0, 0, 0, 0.4);
            margin: 0 4px;
        }

        .dropdownMenuItem {
            position: relative;

            .dropdownMenu {
                position: absolute;
                top: calc(100% + 8px);
                left: 50%;
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.1);
                padding: 4px;
                border-radius: 4px;
                z-index: 100000;
            }
        }
    }

    &:not(.readonly) {
        .editorContent {
            height: 200px;
            overflow-y: scroll;
            overscroll-behavior: contain;
            background: var(--secondary-background-color);
            border-width: 0 1px 1px 1px;
            border-color: rgba(0, 0, 0, 0.1);
            border-style: solid;
            box-sizing: content-box;
            border-radius: 0 0 4px 4px;
            display: flex;
            flex-direction: column;
            resize: vertical;

            .editor {
                padding: 8px 10px;
                cursor: text;
                color: var(--light-text-color);
                flex: 1;

                &:focus {
                    outline: 1px solid rgba(0, 0, 0, 0.35);
                }

                .hasFocus {
                    background: rgba(0, 0, 0, 0.025);
                }

                .heading {
                    color: var(--dark-text-color);
                    padding: 0.25em 0;
                    margin: 0;
                }

                .blockQuote {
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    background: rgba(0, 0, 0, 0.025);
                    padding: 8px;
                    margin: 4px 8px;
                }
            }
        }
    }
}

.heading {
    color: var(--dark-text-color);
    padding: 0.25em 0;
    margin: 0;
}

.blockQuote {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.025);
    padding: 8px;
    margin: 4px 8px;
}

.placeholder {
    color: red;
    content: attr('data-placeholder');
    float: left;
    pointer-events: none;
    height: 0;
}
