.loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .tabs {
        padding-left: 16px;
        background: var(--secondary-background-color);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}
