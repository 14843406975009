@import './ErrorBoundary.module.scss';

.container {
    align-items: flex-start;

    .innerContainer {
        margin: 40px 40px 0;

        .iconContainer {
            font-size: 2.5em;
        }

        .body {
            .content {
                margin-bottom: 10px;
            }

            .information {
                .retry {
                    margin-bottom: 40px;
                    justify-content: flex-end;
                    display: flex;
                }
            }
        }
    }
}
