.container {
    width: calc(min(800px, 100%));
    min-height: 100%;
    padding: 40px 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .bodyContainer {
        width: calc(min(800px, 100%));
        height: min-content;
        display: flex;
        flex-direction: column;

        .stepsContainer {
            display: flex;
            flex-direction: column;
            margin-bottom: 8px;

            .noStepsMessage {
                color: var(--xlight-text-color);
                font-style: italic;
                text-align: center;
                margin: 20px 0 40px;
            }
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            font-size: 0.85rem;
            color: var(--xlight-text-color);
        }
    }

    .actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
    }
}
