.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 12px;
    overflow: auto;

    .row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
        gap: 12px;

        .card {
            background: var(--secondary-background-color);
            box-shadow: 2px 2px 8px 2px rgba(0,0,0,.05);
            padding: 16px;
            margin: 8px;
            border: 1px solid #ecebeb;
            border-radius: 4px;
        }
    }
}
