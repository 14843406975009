.container {
    .innerContainer {
        width: calc(min(100%, 500px));
        padding: 20px 30px;
        margin: 0 auto;

        @media screen and (max-width: 600px) {
            padding: 10px;
        }

        .formContainer {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .orContainer {
                position: relative;
                text-align: center;
                margin: 12px;

                &::before {
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: var(--xlight-text-color);
                    display: block;
                    content: '';
                    position: absolute;
                    top: 50%;
                }

                .orSeparator {
                    font-size: 0.75rem;
                    color: var(--xlight-text-color);
                    font-weight: bold;
                    position: relative;
                    z-index: 2;
                    background: var(--primary-background-color);
                    display: inline;
                    padding: 0 4px;
                }
            }

            .input {
                display: flex;
                gap: 6px;
            }

            .input:not(:first-child) {
                margin: 4px 0 0;
            }

            .submitButton {
                margin: 20px 0;
            }
        }
    }
}
