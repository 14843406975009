.sidePanel {
    z-index: 1;
    display: flex;
    background: var(--primary-background-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-width: 0 0 1px 1px;

    .content {
        height: 100%;
        background: var(--primary-background-color);
        overflow: auto;

        &.modal,
        &.overlay {
            position: absolute;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: -2px 8px 8px 2px rgba(0, 0, 0, 0.05);
            right: 30px;
        }

        &.overlay {
            top: 0;
            left: 0;
            height: 100%;
            width: calc(100% - 30px);
        }
    }

    .tabs {
        width: 30px;
        height: 100%;
        border: solid rgba(0, 0, 0, 0.1);
        border-width: 0 1px;
        background: #ffffff;

        .tab {
            display: flex;
            flex-direction: column;
            min-height: 108px;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            color: var(--medium-text-color);
            cursor: pointer;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            border-left: 2px solid transparent;
            transition: border-color 0.25s linear;

            &.selected {
                border-left: 2px solid rgba(0, 112, 210, 0.7);
            }

            &:hover {
                color: var(--secondary-theme-color-light);
            }

            svg {
                margin-bottom: 6px;
            }

            .label {
                writing-mode: vertical-lr;
                -ms-writing-mode: tb-lr;
                -webkit-writing-mode: 'vertical-lr';
            }
        }
    }
}
