.container {
    width: max-content;
    min-height: 100%;
    padding: 40px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .error {
        text-align: center;
        display: flex;
        flex-direction: column;
        color: var(--medium-text-color);

        & > * {
            margin: 10px 0;
        }

        & > p {
            color: var(--light-text-color);
        }

        a, a:visited {
            color: var(--primary-theme-color-light);
        }

        a:hover {
            color: var(--primary-theme-color);
        }
    }

    .errors {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 20px;
    }

    .actions {
        display: flex;
        justify-content: flex-end;
    }
}