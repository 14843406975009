.container {
    padding: 20px 30px;

    .message {
        color: var(--light-text-color);
        margin-bottom: 30px;
        font-size: .9rem;
    }

    .recentJobContainer {
        margin: 30px 8px 20px;

        .recentJobHeader {
            color: var(--light-text-color);
            margin-bottom: 8px;
            font-weight: 600;
            font-size: 0.85rem;
        }

        .jobList {
            border-radius: 4px;
            overflow: hidden;

            .job {
                font-size: 0.8rem;
                color: var(--light-text-color);
                padding: 6px;
                background: #fafafa;
                cursor: pointer;

                &:nth-child(even) {
                    background: #f1f1f1;
                }

                &:hover {
                    background: #dfdfdf;
                    text-decoration: underline;
                }

                &.selected {
                    background: #b9dafc;
                    color: var(--secondary-theme-color);
                }
            }
        
            .noRecentJobs {
                color: var(--xlight-text-color);
                font-size: .8rem;
            }
        }
    }
}
