.confirmationMessage {
    color: var(--dark-text-color);
    font-size: 0.95rem;
    margin-bottom: 24px;
    margin-left: 20px;
}

.resourceContainer {
    font-size: 0.85rem;
    line-height: 1.1rem;
    margin-left: 20px;

    .resourceMessage {
        color: var(--medium-text-color);
    }

    .resourceList {
        color: var(--light-text-color);
        font-size: .75rem;
    }
}
