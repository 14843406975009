.dateRangeContainer {
    display: flex;
    justify-content: space-between;
}

.pickerContainer {
    flex: 1;
    display: flex;
}

.pickerContainer:first-child {
    margin-right: 10px;
}
