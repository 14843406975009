.container {
    padding: 40px 0;
    width: calc(min(800px, 100%));

    .noStepsMessage {
        color: var(--xlight-text-color);
        font-style: italic;
        text-align: center;
        margin: 20px 0 40px;
    }

    .header {
        display: flex;
        font-weight: bold;
        color: var(--medium-text-color);
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        padding: 4px 0;
        margin-bottom: 10px;
    }

    .row {
        display: flex;
        align-items: center;
        margin: 5px 0;
        font-size: 0.9rem;
        color: var(--medium-text-color);
    }

    .responsibleParty, .emailAddress {
        flex: 1;
    }

    .required {
        width: 65px;
    }

    .header > .actions,
    .row > .actions {
        width: 40px;
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: .8rem;
        color: var(--xlight-text-color);

        & > * {
            padding: 4px;
            cursor: pointer;
        }

        & > :hover {
            color: var(--medium-text-color);
        }
    }

    & > .actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
}
