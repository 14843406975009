.container {
    display: inline-block;
    width: 100%;
    height: 60px;
    margin: 2px 0;

    .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
        min-width: 200px;
        max-width: 340px;
        height: 60px;
        page-break-inside: avoid;

        .upper {
            display: inline-flex;
            justify-content: space-between;
            user-select: none;

            .label {
                display: flex;
                align-items: center;
                font-size: 0.8rem;
                margin: 0 6px;
                color: var(--light-text-color);
                margin-right: 10px;
                min-width: 100px;

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .signature {
                display: flex;
                align-items: center;
                max-width: 260px;
                margin-right: 20px;

                img {
                    max-width: 260px;
                    max-height: 46px;
                    transform: translateZ(0) scale(0.999);
                }
            }

            .actions {
                display: flex;

                .action {
                    display: flex;
                    align-items: center;

                    & button {
                        border: none;
                        color: inherit;
                        background: none;
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                    }

                    .add {
                        color: #0c9b00;
                    }

                    .remove {
                        color: var(--medium-text-color);
                        opacity: 0.7;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .lower {
            .timestamp {
                display: flex;
                justify-content: flex-end;
                color: var(--light-text-color);
                font-size: 0.75rem;
            }
        }
    }
}
