.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    width: 100%;

    .header {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        color: var(--medium-text-color);

        .headerInnerContainer {
            display: flex;
            justify-content: space-between;
            width: calc(min(100%, 800px));

            & > * {
                align-self: center;
                flex-basis: 0;
                white-space: nowrap;
                flex: 1;
                text-align: center;
            }

            .stepCount {
                font-size: 0.9rem;

                .finishedSteps {
                    color: var(--primary-theme-color-light);
                }
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        overflow-y: auto;
        padding: 0 10px;

        .bodyInnerContainer {
            width: calc(min(100%, 800px));
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 30px 0;
            font-size: 0.9rem;
            color: var(--light-text-color);

            .contacts {
                margin: 8px 0;

                .contactsButton {
                    margin: 8px 0;
                }
            }

            .submitContainer {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-top: 20px;
                gap: 8px;
            }
        }
    }
}

.savedContainer {
    max-width: 500px;
    margin: 40px auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    line-height: 1.5rem;

    h3 {
        color: var(--medium-text-color);
        margin: 0;
    }

    p {
        color: var(--light-text-color);
        margin: 16px 0;
    }

    .actions {
        display: flex;
        justify-content: center;
        gap: 30px;
    }
}
