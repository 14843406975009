.container {
    width: 100%;
    min-height: 100%;
    //padding: 10px 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .bodyContainer {
        width: 100%;

        .equipmentContainer {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
        }

        .noStepsMessage {
            color: var(--xlight-text-color);
            font-style: italic;
            margin: 20px 0 40px;
            text-align: center;
        }

        .actions {
            width: 100%;

            & > * {
                float: right;
            }
        }
    }
}
