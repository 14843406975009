.datePicker{
    width: 100%;
    height: 100%;
    border: none;
}

.datePicker > :global(.react-date-picker__wrapper){
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 0;
}

.datePicker :global(.react-date-picker__inputGroup__input:invalid){
    background: rgba(255, 0, 0, 0.3);
}