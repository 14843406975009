.root {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

    .container {
        max-width: 960px;
        padding: 0 20px;

        @media (min-width: 550px) {
            padding: 0 40px;
        }

        @media (min-width: 760px) {
            padding: 0 60px;
        }

        .header {
            padding: 0 6px;
            margin-bottom: 28px;

            .title {
                color: var(--medium-text-color);
                font-size: 24px;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: -4px;
                    left: -6px;
                    height: 1px;
                    width: calc(100% + 12px);
                    background: #e3e3e3;
                }
            }

            .subtitle {
                color: var(--light-text-color);
                font-style: italic;
                font-size: 13px;
            }
        }

        .body {
            padding: 0 6px;

            .section {
                padding: 10px 0;
                display: flex;
                flex-direction: column;

                @media (min-width: 720px) {
                    & {
                        flex-direction: row;
                    }
                }

                .disclaimer {
                    display: inline-flex;
                    align-items: center;
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 12px;
                    font-style: italic;
                    margin: 0 8px 8px 0;

                    .red {
                        color: #a61a14;
                        margin-right: 4px;
                    }
                }

                @media (min-width: 456px) {
                    .disclaimer {
                        margin: 0 8px 0 0;
                    }
                }

                .submit {
                    margin: 0;
                }

                .area-of-suggestion {
                    width: 220px;
                }

                .name {
                    width: 100%;
                }

                .followup {
                    margin-left: 6px;
                    justify-content: flex-end;
                    margin-top: 10px;
                }

                @media (min-width: 720px) {
                    .followup {
                        margin-left: 40px;
                        margin-top: 0;
                    }
                }

                .jobsite {
                    width: 250px;
                }

                .suggestion {
                    width: 100%;
                }

                @media (min-width: 630px) {
                    .suggestion {
                        width: 570px;
                    }

                    .name {
                        width: 400px;
                    }
                }
            }

            .vertical-section {
                flex-direction: column;
            }

            .horizontal-section {
                flex-direction: row;
            }

            .flex-end {
                justify-content: flex-end;
            }
        }
    }
}
