.profileContainer {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 17px;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    & > div:not(:last-child) {
        padding-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profileImageContainer > img {
        height: 32px;
        width: 32px;
        border-radius: 50%;
    }

    .profileName {
        color: var(--contrast-dark-text-color);
        font-size: 0.8rem;
        font-weight: 600;
    }

    .profileDropdownIndicator {
        font-size: 0.6rem;
        color: var(--contrast-medium-text-color);
        transform: translateY(10%);
        padding-left: 8px;
    }
}

.profileDropdownContainer {
    padding: 19px;
    display: flex;
    position: relative;
    height: 132px;
    width: 326px;
    box-shadow: 1px 1px 12px 6px rgba(0, 0, 0, 0.1);

    .profileDropdownImage {
        flex: 0;
        min-width: 124px;
        display: flex;
        align-items: center;
    }

    .profileDropdownImage > img {
        width: 88px;
        height: 88px;
        border-radius: 50%;
    }

    .profileDropdownLogout {
        position: absolute;
        display: flex;
        bottom: 14px;
        right: 20px;
        font-size: 12px;
        cursor: pointer;
        color: var(--light-text-color);

        &:hover {
            color: var(--medium-text-color);
        }

        .logoutIcon {
            margin-right: 4px;
        }
    }

    .profileDropdownOptions {
        overflow: hidden;

        & > div {
            padding: 1px;
            margin-top: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .profileOptionName {
            font-size: 16px;
            font-weight: 700;
            color: var(--dark-text-color);
        }

        .profileOptionEmail {
            font-size: 13px;
            color: var(--medium-text-color);
        }

        .profileOptionHelp {
            color: #0078d6;
            font-size: 13px;

            & > a {
                text-decoration: none;
                color: inherit;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .profileOptionFeedback {
            font-size: 13px;
            color: #0078d6;
            cursor: pointer;

            & > a {
                text-decoration: none;
                color: inherit;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
