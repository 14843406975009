.card {
    flex: 1;
}

.content {
    padding-bottom: 0!important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    color: var(--light-text-color);
    border-bottom: 1px dotted #bdbdbd;
    padding: 6px 0;
    font-size: .8rem;
    max-width: 100%;
    gap: 8px;
    justify-content: space-between;

    &.header{
        color: var(--dark-text-color);
        font-weight: 600;
        font-size: .9rem;
    }

    .name {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 200px;
        width: 300px;
        
        p {
            display: inline;
            white-space: nowrap;
            cursor: pointer;

            &:hover{
                text-decoration: underline;
            }
        }
    }

    .equipment {
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 250px;
        
        p {
            display: inline;
            white-space: nowrap;
            cursor: pointer;

            &:hover{
                text-decoration: underline;
            }
        }
    }

    .date {
        min-width: 150px;
        width: 150px;
        text-align: left;
    }

    .step {
        width: 120px;
        text-align: left;
    }

    .actions {
        width: 50px;
        display: flex;
        justify-content: flex-start;
        gap: 4px;
        margin-top: -2px;

        .action {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.6;
            cursor: pointer;
            padding: 0 4px;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.noForms {
    padding: 24px 0;
    font-size: .85rem;
    text-align: center;
    color: var(--xlight-text-color);
    border-bottom: 1px dotted #bdbdbd;
}

.footer {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .85rem;
    color: var(--secondary-theme-color-light);
    cursor: pointer;

    & > p:hover{
        color: var(--secondary-theme-color);
        text-decoration: underline;
    }
}