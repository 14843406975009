.contextCell {
    display: flex;
    overflow: hidden;
    text-wrap: nowrap;

    .tooltipContainer {
        min-width: min-content;

        .contextLabel {
            color: var(--secondary-theme-color-light);
            cursor: pointer;
            margin-left: 6px;
            text-decoration: underline;

            &:hover {
                color: var(--secondary-theme-color);
            }
        }
    }
}
