.selected,
.selected:global(.BaseTable__row--hovered),
.selected:global(.BaseTable__row:hover) {
    background: #e0f5fd !important;
}

.disabled:global(.BaseTable__row),
.disabled:global(.BaseTable__row--hovered),
.disabled:global(.BaseTable__row:hover) {
    background: rgba(0, 0, 0, 0.03) !important;
    opacity: 0.4;
    cursor: unset;
}

:global(.BaseTable__row).even:not(.disabled):not(.selected) {
    background: #ffffff;
}

:global(.BaseTable__row):not(.even):not(.disabled):not(.selected)
    :global(.BaseTable__row-cell) {
    background: #fbfcfc;
}
