@import './Card.module.scss';

@property --p {
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
}

.card {
    min-width: calc(min(100%, 760px));
}

.container {
    display: flex;
    flex-wrap: wrap;

    .jobLabel {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        font-size: 1rem;
        color: var(--medium-text-color);
        font-weight: bold;

        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .edit {
            color: var(--xlight-text-color);
            font-size: 0.75rem;
            margin-left: 8px;
            padding: 8px;
            border-radius: 50%;
            cursor: pointer;

            &:hover {
                color: var(--medium-text-color);
                background: #f0f0f0;
            }
        }
    }

    .metricSection {
        display: flex;
    }

    .metricContainer {
        position: relative;
        margin: 12px 0;
        width: 300px;
        border-bottom: 1px dotted #bdbdbd;

        .metricLabel {
            display: flex;
            align-items: center;
            color: var(--light-text-color);
            font-weight: 400;
            margin-bottom: 12px;

            & > * {
                margin-right: 8px;
            }

            .metricIcon {
                color: var(--primary-theme-color-light);
            }
        }

        .metricValue {
            color: var(--medium-text-color);
            font-size: 0.9rem;
            padding: 4px;
            font-weight: 400;

            & > p {
                display: inline-block;
            }

            .metricValueLight {
                display: inline-block;
                color: var(--light-text-color);
            }
        }

        .metricQualifier {
            position: absolute;
            right: 8px;
            bottom: 4px;
            font-size: 0.85rem;
        }
    }

    .leftContainer {
        max-width: 50%;
    }

    .rightContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        margin: 12px;
        gap: 16px;

        .formActionsContainer {
            display: flex;
            flex: 1;
            width: 100%;
            align-items: center;
            justify-content: center;
            gap: 20px;

            .formAction {
                padding: 8px 16px;
                border-radius: 8px;
                text-align: center;
                justify-content: center;
                background-color: #ececec;
                box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.04);
                cursor: pointer;
                width: 190px;

                &:hover {
                    background-color: #dedede;
                    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.08);
                }

                & > p {
                    font-size: 0.8rem;
                    color: var(--light-text-color);
                }

                & > p:first-child {
                    font-size: 1rem;
                    font-weight: bold;
                    margin-bottom: 4px;
                    margin-top: -4px;
                }
            }
        }

        .filterContainer {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            width: 100%;

            & > * {
                min-width: calc(50% - 8px);
                width: calc(50% - 8px);
            }

            .resetFilters {
                display: flex;
                align-items: center;
                gap: 4px;

                & > div {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    font-size: 0.9rem;
                    color: var(--light-text-color);
                    cursor: pointer;

                    &:hover {
                        color: var(--medium-text-color);
                    }
                }
            }
        }

        .graphContainer {
            flex: 2;
            width: 100%;
            display: grid;
            grid-template-columns: 150px 150px;
            grid-template-rows: auto auto;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            justify-content: center;
            align-items: center;

            .pieContainer {
                display: flex;
                align-items: center;
                justify-content: center;

                .pie {
                    @keyframes p {
                        from {
                            --p: 0;
                        }
                    }

                    --w: 130px;
                    width: var(--w);
                    height: var(--w);
                    aspect-ratio: 1;
                    display: inline-grid;
                    place-content: center;
                    padding: 16px;
                    position: relative;
                    text-align: center;
                    animation: p 2s 0.25s both;
                    border-radius: 50%;
                    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1);

                    & > p,
                    .pieAction > p {
                        font-size: 0.75rem;
                    }

                    & > p:first-child {
                        font-size: 1.4rem;
                        font-weight: bold;
                        margin-bottom: 4px;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        border-radius: 50%;
                        inset: 0;
                        background: radial-gradient(
                                    farthest-side,
                                    var(--c) 98%,
                                    #0000
                                )
                                top/var(--b) var(--b) no-repeat,
                            conic-gradient(
                                var(--c) calc(var(--p) * 1%),
                                #f0f0f0 0
                            );
                        -webkit-mask: radial-gradient(
                            farthest-side,
                            #0000 calc(99% - var(--b)),
                            #000 calc(100% - var(--b))
                        );
                        mask: radial-gradient(
                            farthest-side,
                            #0000 calc(99% - var(--b)),
                            #000 calc(100% - var(--b))
                        );
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        border-radius: 50%;
                        inset: 0;
                        inset: calc(50% - var(--b) / 2);
                        background: var(--c);
                        transform: rotate(calc(var(--p) * 3.6deg))
                            translateY(calc(50% - var(--w) / 2));
                    }

                    &.noRound:before {
                        background-size: 0 0, auto;
                    }

                    &.noRound:after {
                        content: none;
                    }

                    .pieAction {
                        cursor: pointer;
                        z-index: 5;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
