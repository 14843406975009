.container {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    max-width: 100%;
}

.tooltip {
    display: inline-flex;
    position: absolute;
    animation: appear 0.45s;
    pointer-events: auto;
}

.textTooltip {
    padding: 7px 12px 4px 12px;
    background: #4b4b4b;
    border-radius: 4px;
    min-height: 22px;
    align-items: center;
    font-size: 0.75rem;
    color: var(--contrast-medium-text-color);
    font-weight: bold;
    line-height: 1.15rem;
    max-width: 400px;
    word-break: break-all;
}

.textTooltipContent {
    line-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
