.actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .action,
    .action:hover {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        padding: 0 6px;
        margin: 0;
        font-size: 0.8rem;
        background: none;
        box-shadow: none;
        border: none;
    }
}
