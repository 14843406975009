.container {
    display: inline-flex;
    align-items: center;
    background: #d8ebff;
    color: var(--secondary-theme-color);
    padding: 4px 10px;
    border-radius: 20px;
    font-size: .75rem;
    margin-top: 2px;

    .left {
        margin-right: 6px;
    }

    .label {
        flex: 1;
        margin-top: 2px;
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        width: 14px;
        min-width: 14px;
        height: 14px;
        cursor: pointer;
        opacity: 0.75;
        border-radius: 50%;

        &:hover {
            opacity: 1;
        }
    }

    .chipTextInput {
        background: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid var(--secondary-theme-color);
        border-radius: 0;
        margin: 0 4px;
        height: 20px;
        min-height: 20px;
        padding-left: 0;
    }
}
