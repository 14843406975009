.buttonContainer {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px 12px;
    border-radius: 4px;
    color: var(--light-text-color);
    font-size: 13px;
    cursor: pointer;
    width: 100%;

    &.toggled {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .valueLabel {
        flex-grow: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 10px;
    }

    .placeholder {
        color: var(--xlight-text-color);
    }

    @media screen and (max-width: 767px) {
        .valueLabel,
        .placeholder {
            font-size: 16px;
        }
    }

    .activeIcon {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--xlight-text-color);
        font-size: 10px;
    }

    &:hover .activeIcon,
    .toggled {
        color: var(--medium-text-color);
    }
}

.modalContainer {
    height: 400px;
}

.contentContainer {
    min-width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: var(--medium-text-color);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    height: 37.5px;

    .count {
        font-size: 13px;
        color: var(--light-text-color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 4px;

        .placeholder {
            color: var(--xlight-text-color);
        }

        @media screen and (max-width: 767px) {
            &,
            & .placeholder {
                font-size: 16px;
            }
        }
    }

    .options {
        display: flex;
        font-size: 16px;

        & > .option {
            margin: 0 4px;
        }

        .option {
            display: flex;
            align-items: center;
            padding: 4px;
            cursor: pointer;

            &.active,
            &:hover {
                color: var(--secondary-theme-color-light);
            }
        }
    }
}

.searchContainer {
    padding: 0 12px 6px;
    min-height: 32px;
}

.optionContainer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    flex: 1;

    .optionHeader {
        border: none !important;
    }

    .gridContainer {
        flex-direction: column;
        height: 100%;
        width: 100%;
        flex: 1;
    }
}

.actionsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 56px;
    padding: 6px 12px;
    font-size: 13px;
}
