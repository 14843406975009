.container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;

    .tab {
        margin: 0 8px;
        background: #cccccc;
        height: 18px;
        border-radius: 4px;
    }
}