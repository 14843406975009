.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 29px;
    padding: 6px 12px;
    font-size: 13px;
    //margin: 0 10px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
    border: none;
    position: relative;
    user-select: none;
    text-decoration: none!important;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
    }

    .icon.right:not(:only-child) {
        margin-left: 8px;
    }

    .icon.left:not(:only-child) {
        margin-right: 8px;
    }

    .dropdownIcon {
        margin-left: 8px;
        font-size: 14px;
    }

    .loader {
        .secondary-loader {
            border: 2px solid #abd8ff;
            border-right: 2px solid var(--secondary-theme-color-light);
        }

        .primary-loader {
            border: 2px solid rgba(255, 255, 255, 0.3);
            border-right: 2px solid rgba(255, 255, 255, 0.8);
        }

        .grayscale-loader {
            border: 2px solid rgba(0, 0, 0, 0.15);
            border-right: 2px solid rgba(0, 0, 0, 0.4);
        }
    }
}

.dropdownButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
    padding: 0;
    //margin: 0 10px;
    z-index: 1;

    .button {
        margin: 0;
    }

    .menu {
        position: absolute;
        top: calc(100% + 4px);
        font-size: 12px;
        left: 0;
        min-width: 150px;
        border-radius: 4px;
        background: #ffffff;
        box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(0, 0, 0, 0.1);

        .option {
            display: flex;
            align-items: center;
            padding: 7px 22px;
            white-space: nowrap;

            &:hover {
                cursor: pointer;
                background: rgba(0, 0, 0, 0.03);
            }

            & > *:not(:first-child) {
                margin-left: 8px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.button:hover {
    .secondary-loader {
        border: 2px solid #abd8ff;
        border-right: 2px solid #ffffff;
    }
    .primary-loader {
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-right: 2px solid rgba(255, 255, 255, 0.8);
    }
}

.disabled {
    opacity: 0.4 !important;
    cursor: unset;
}

.primary {
    &.filled {
        color: var(--contrast-dark-text-color);
        background: var(--primary-theme-color-light);
        border: 1px solid var(--primary-theme-color-light);

        &:not(.disabled):hover,
        &:not(.disabled).toggled {
            background: var(--primary-theme-color);
            border: 1px solid var(--primary-theme-color);
        }
    }

    &.border {
        background: #ffffff;
        border: 1px solid var(--primary-theme-color-light);
        color: var(--primary-theme-color-light);

        &:not(.disabled):hover,
        &:not(.disabled).toggled {
            background: var(--primary-theme-color-light);
            color: var(--contrast-dark-text-color);
        }
    }

    font-weight: 600;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.05);

    &:not(.disabled):hover {
        box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
    }

    &.text {
        background: transparent;
        border: none;
        color: var(--primary-theme-color-light);
        box-shadow: none;
        padding: 0;

        &:not(.disabled):hover,
        &:not(.disabled).toggled {
            color: var(--primary-theme-color);
            box-shadow: none;
        }
    }
}

.secondary {
    &.filled {
        background: var(--secondary-theme-color-light);
        border: 1px solid var(--secondary-theme-color-light);
        color: var(--contrast-dark-text-color);

        &:not(.disabled):hover,
        &:not(.disabled).toggled {
            border: 1px solid var(--secondary-theme-color);
            background: var(--secondary-theme-color);
        }
    }

    &.border {
        background: #ffffff;
        border: 1px solid var(--secondary-theme-color-light);
        color: var(--secondary-theme-color-light);

        &:not(.disabled):hover,
        &:not(.disabled).toggled {
            background: var(--secondary-theme-color-light);
            color: var(--contrast-dark-text-color);
        }
    }

    font-weight: 600;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.05);

    &:not(.disabled):hover {
        box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
    }

    &.text {
        background: transparent;
        border: none;
        color: var(--secondary-theme-color-light);
        box-shadow: none;
        padding: 0;

        &:not(.disabled):hover,
        &:not(.disabled).toggled {
            color: var(--secondary-theme-color);
            box-shadow: none;
        }
    }
}

.grayscale {
    font-weight: 600;
    box-shadow: none;

    &.filled {
        background: rgba(0, 0, 0, 0.06);
        color: var(--xlight-text-color);
        border: 1px solid #ececec;

        &:not(.disabled):hover {
            background: rgba(0, 0, 0, 0.06);
            color: var(--light-text-color);
        }
    }

    &.border {
        background: #ffffff;
        border: 1px solid #ececec;
        color: var(--xlight-text-color);

        &:not(.disabled):hover {
            background: rgba(0, 0, 0, 0.06);
            color: var(--light-text-color);
        }
    }
}
