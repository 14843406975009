.formContainer {
    width: calc(min(800px, 100%));
    max-width: 800px;
    //padding: 0 20px;
    height: min-content;
    display: flex;
    flex-direction: column;

    .formBody {
        .section {
            display: flex;
            flex-direction: column;
            gap: 18px;
            padding: 40px 0;

            &:first-child {
                padding: 0 0 40px 0;
            }

            .header {
                color: var(--medium-text-color);
                text-decoration: underline;
                margin: unset;
            }

            /* &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            } */

            p.readonlyField {
                font-size: 0.8rem;
                color: var(--light-text-color);
            }

            .row {
                display: flex;
                gap: 16px;
                flex-direction: column;

                @media only screen and (min-width: 675px){
                    & {
                        flex-direction: row;
                        gap: 32px;
                    }
                }

                .datePicker {
                    width: 100%;
                }
            }
        }
    }

    .actions,
    .formErrors {
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
    }
}
