.attachmentLink {
    color: var(--secondary-theme-color);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.iconAttachmentLink {
    border-bottom: 1px solid var(--secondary-theme-color);
    color: var(--secondary-theme-color);
}

.completed,
.incomplete {
    display: flex;
    align-items: center;
    gap: 4px;
}

.noForms {
    display: flex;
    align-items: center;
    color: rgba(0,0,0,0.25);
}

.completed {
    svg {
        color: var(--success-text-color);
    }
}

.incomplete {
    svg {
        color: var(--error-text-color);
    }
}
