@keyframes circle-loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader.simpleCircle {
    transform-origin: center center;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-right-color: rgba(255, 255, 255, 0.7);
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    border-radius: 50%;
    animation: circle-loader 1.2s infinite linear;
}
