.gridContainer {
    display: flex;
    height: 100%;
    position: relative;
    overflow: hidden;

    .grid {
        height: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        background: var(--secondary-background-color);
        position: relative;

        .header {
            display: flex;
            align-items: flex-end;
            height: 76px;
            margin-bottom: 4px;

            .action {
                height: 29px;
                width: 80px;
                background: #ececec;
                margin: 10px;
                border-radius: 4px;
            }
        }

        .body {
            flex: 1;
            overflow: hidden;

            .gridHeader {
                display: flex;
                background: #ececec;
                border: solid #dedede;
                border-width: 1px 0;
                height: 42px;

                .cell:first-child {
                    .label {
                        display: none;
                    }
                }
            }

            .cell {
                display: flex;
                align-items: center;
                flex: 1;
                border-right: 1px solid #cccccc;
                padding-left: 12px;

                .label {
                    background: #dedede;
                    height: 20px;
                    width: 20px;
                    border-radius: 4px;
                }

                &:first-child {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    max-width: 50px;
                    padding-left: 0;
                }
            }

            .gridItem {
                display: flex;
                height: 38px;
                border-bottom: 1px solid #eeeeee;

                .cell {
                    border-right: none;
                    
                    .label {
                        background: #f1f1f1;
                    }
                }
            }
        }
    }

    .sidePanel {
        width: 30px;
        height: 100%;
        border: solid rgba(0, 0, 0, 0.1);
        border-width: 0 1px;
        background: #ffffff;

        .sidePanelItem {
            min-height: 108px;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 4px 0;

            .icon {
                height: 18px;
                width: 18px;
                border-radius: 4px;
                background: #f1f1f1;
                border-radius: 4px;
                margin: 4px 12px;
            }

            .label {
                flex: 1;
                background: #f1f1f1;
                width: 16px;
                margin: 4px 12px;
                border-radius: 4px;
            }
        }
    }
}
