.container {
    width: 450px;
    background: var(--secondary-background-color);
    z-index: 10000000000;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        padding: 0 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: var(--primary-background-color);
        font-size: 13px;

        .close {
            display: flex;
            align-items: center;
            font-weight: 700;
            color: var(--light-text-color);
            cursor: pointer;
            line-height: 0;

            &:hover {
                color: var(--dark-text-color);
            }

            .label {
                margin-left: 6px;
            }
        }

        .actions {
            display: flex;
            color: var(--light-text-color);

            .action {
                margin: 0 4px;
                cursor: pointer;
                padding: 6px;

                &:hover {
                    color: var(--secondary-theme-color-light);
                }

                &:last-child {
                    margin: 0 0 0 4px;
                }
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        flex-basis: 0px;
        position: relative;
        overflow: hidden;

        .content {
            flex: 1 1;
            flex-basis: 0px;
            overflow: hidden;

            .container {
                display: flex;
                flex-direction: column;
                overflow: auto;
                height: 100%;
                flex: 1 1 auto;
                padding: 40px 28px;

                .section {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .loading {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &:not(:last-child) {
                        margin-bottom: 60px;
                    }

                    .detailGroup {
                        display: flex;
                        margin: 20px 0;

                        &:first-child {
                            margin: 0 0 20px 0;
                        }

                        .detailProperty {
                            display: flex;
                            flex-direction: column;
                            flex: 1;

                            &:not(:first-child) {
                                margin-left: 20px;
                            }

                            .detailLabel {
                                color: var(--medium-text-color);
                                font-weight: 600;
                                padding-bottom: 4px;
                            }

                            .detailValue {
                                color: var(--light-text-color);
                                font-size: 13px;
                                padding-left: 4px;
                            }

                            .reviewedBy {
                                display: flex;
                                font-size: 13px;
                                margin-top: 6px;
                                justify-content: flex-end;
                                align-items: center;

                                .icon {
                                    color: #0cc808;
                                    margin-right: 4px;
                                    font-size: 11px;
                                }

                                .value {
                                    color: var(--dark-text-color);
                                }
                            }
                        }
                    }
                }
            }

            .noSelection {
                display: flex;
                align-items: center;

                .message {
                    width: 60%;
                    color: var(--light-text-color);
                    text-align: center;
                }
            }
        }
    }
}
