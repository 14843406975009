.container {
    height: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .section {
        display: flex;
        flex-direction: column;

        &:not(:first-child) {
            margin: 30px 0 0;
        }

        .detailGroup {
            display: flex;
            margin: 20px 0;
            flex: 1 0 0;

            .detailProperty {
                flex-basis: 0;
                &:not(:first-child) {
                    margin-left: 10px;
                }
            }

            .flexGrow {
                flex: 1;
            }
        }

        .row {
            flex-direction: row;

            .detailProperty {
                &:not(:first-child) {
                    margin-top: 0;
                    margin-left: 30px;
                }
            }
        }
    }
}
