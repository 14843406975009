.error {
    display: flex;
    align-items: center;
    color: var(--error-color);
    font-size: 0.8rem;
    width: 100%;
}

.icon {
    color: #c30404;
}

.message {
    margin-left: 12px;
    color: var(--medium-text-color);
}
