.lineContainer {
    position: relative;

    .removeLine {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        padding: 4px;
        cursor: pointer;
        color: var(--light-text-color);
        opacity: .75;

        .removeIcon {
            font-size: .75rem;
        }

        &:hover {
            opacity: 1;
            color: var(--medium-text-color);
        }
    }

    .fieldContainer {
        font-size: 0.75rem;
        text-align: center;
        padding: 8px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        position: relative;
    }
}

.newItem {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px;
    font-size: 0.8rem;
    line-height: 0;
    cursor: pointer;
    color: var(--light-text-color);

    .icon {
        color: var(--primary-theme-color);
    }

    &:hover {
        color: var(--medium-text-color);
        background: rgba(0, 0, 0, 0.075);
    }
}

.confirmationMessage {
    color: var(--light-text-color);
    font-size: 0.95rem;
    margin-bottom: 24px;
}
