.container {
    position: relative;

    .copyContainer {
        display: inline-block;
        position: relative;

        .tooltip {
            position: absolute;
            left: calc(100% + 6px);
            top: 50%;
            transform: translateY(-50%);
            background: #494949;
            padding: 4px 8px;
            font-size: 0.75rem;
            border-radius: 3px;
            color: white;
            font-weight: 700;
            white-space: nowrap;

            &::after {
                content: '';
                display: block;
                border: 4px solid transparent;
                border-right: 4px solid #494949;
                position: absolute;
                bottom: 50%;
                right: 100%;
                transform: translateY(50%);
            }
        }
    }

    .copy {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.6);

        &:hover {
            color: var(--primary-theme-color-light);
        }
    }
}

@media screen and (min-width: 700px) {
    .container {
        .copyContainer {
            .tooltip {
                top: calc(100% + 6px);
                left: 50%;
                transform: translateX(-50%);

                &::after {
                    content: '';
                    display: block;
                    border: 4px solid transparent;
                    border-bottom: 4px solid #494949;
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}
