.headerCellSortable {
    cursor: pointer;
}

.headerCellSortable:hover {
    background: rgba(0, 0, 0, 0.04);
}

.headerCellLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.headerCellSortIcon {
    user-select: none;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    display: block;
    position: relative;
    color: var(--light-medium-text-color);
    font-size: 0.6rem;
}

.sortOrder {
    position: absolute;
    bottom: -6px;
    right: -4px;
    font-size: 0.5rem;
}
