.dropdownContainer {
    position: relative;

    .buttonContainer {
        width: 100%;
        height: 100%;
    }

    .menuContainer {
        position: absolute;
        top: 100%;
        background: #ffffff;
        min-width: calc(100% + 2px);
        z-index: 10000;
        overflow: hidden;
    }
}
