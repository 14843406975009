.container {
    .message {
        font-size: 11px;
        padding: 6px 8px;
        display: inline-flex;
        align-items: center;
        margin-top: 4px;
        background: #f9d9d9;
        color: #d91726;
        min-height: 32px;

        .icon {
            margin-right: 6px;
        }

        .label {
            margin-top: 3px;
        }
    }
}
