.container {
    position: relative;

    .label {
        position: relative;
        cursor: pointer;
    }

    .content {
        position: absolute;
        background: #ffffff;
        box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.06);
    }
}
