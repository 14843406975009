.container {
    .noFilesMessage {
        color: var(--xlight-text-color);
        font-size: 13px;
        margin-top: 8px;
        margin-left: 4px;
    }

    .fileContent {
        display: flex;
        flex-direction: column;
        //overflow: auto;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.075);
        }

        .fileContainer {
            display: flex;
            font-size: 13px;
            padding: 4px 0;
            margin: 2px 0;

            .thumbnail {
                display: flex;
                justify-content: center;
                margin-right: 8px;
                flex-basis: 0;

                .image {
                    width: 100%;
                    max-height: 24px;
                }

                .icon {
                    color: var(--light-text-color);
                    font-size: 22px;
                }
            }

            .fileDetails {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 12px;
                margin-right: 6px;
                flex: 1;
                //overflow-x: hidden;

                .upperContainer {
                    .name {
                        display: flex;
                        align-items: center;
                        color: var(--light-text-color);
                    }
                }

                .lowerContainer {
                    margin-top: 4px;
                    .description {
                        color: var(--light-text-color);
                        font-style: italic;
                    }
                }
            }

            .fileActions {
                display: flex;

                .fileRemove,
                .fileDownload {
                    margin: 0 1px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--xlight-text-color);
                    cursor: pointer;
                    padding: 4px;
                    width: 23px;
                    max-width: 23px;
                    height: 23px;
                    max-height: 23px;

                    &:hover {
                        color: var(--secondary-theme-color-light);
                    }
                }

                .fileRemove {
                    margin-left: 6px;
                }

                .fileDownload {
                    color: var(--light-text-color);
                }
            }
        }
    }

    .fileActions {
        .fileUpload {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            display: inline-flex;
            align-items: center;
            font-size: 12px;
            margin-top: 8px;
            padding: 2px 8px 2px 2px;
            color: var(--medium-text-color);
            user-select: none;

            .button {
                margin: 0 4px 0 0;
            }
        }

        .maximumFilesMessage {
            display: flex;
            color: var(--light-text-color);
            font-size: 0.8rem;
            margin-top: 8px;

            .maximumFiles {
                font-weight: bold;
                color: var(--medium-text-color);
                margin-left: 4px;
            }
        }

        .downloadAll {
            margin: 8px 0 0;
        }

        & > *:not(:first-child) {
            margin-left: 10px;
        }
    }
}
