.card {
    background: var(--secondary-background-color);
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.05);
    border: 1px solid #ecebeb;
    border-radius: 4px;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;

    &.dark .header {
        background: #404040;
        color: var(--contrast-dark-text-color);

        .actionButton:not(.inactive):hover {
            background: rgba(255, 255, 255, 0.1);
        }
    }

    &.light .header {
        background: transparent;
        color: var(--medium-text-color);

        .actionButton:not(.inactive):hover {
            background: rgba(0, 0, 0, 0.05);
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 46px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.95rem;
        padding: 12px 16px 12px 26px;
    }

    .header {
        .actionButton {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 30px;
            height: 30px;
            border-radius: 50%;

            &.inactive {
                cursor: unset;
                opacity: 0.25;
            }
        }
    }

    .content {
        padding: 16px;
        color: var(--light-text-color);
        font-size: 0.9rem;
    }

    .loadingContainer {
        padding: 60px 0;
        height: 100%;
    }
}
