.container {
    height: 100%;
    width: 100%;
    position: relative;

    .customizePromptContainer {
        margin: 80px auto;
        background: white;
        padding: 20px;
        width: 400px;
        box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.06);
        border: 1px solid rgba(0, 0, 0, 0.1);

        .header {
            margin-bottom: 10px;
            color: var(--medium-text-color);
            font-weight: 500;
        }

        .inputs {
            display: flex;
            flex-direction: column;

            & > * {
                margin: 10px 0;
            }
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;

            & > * {
                margin: 0;
            }
        }
    }
}
