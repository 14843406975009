.content {
    padding-bottom: 0 !important;
}

.row {
    display: flex;
    color: var(--light-text-color);
    border-bottom: 1px dotted #bdbdbd;
    padding: 6px 0;
    font-size: 0.8rem;
    max-width: 100%;

    &.header {
        color: var(--dark-text-color);
        font-weight: 600;
        font-size: 0.9rem;
    }

    .name {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;

        p {
            display: inline;
            white-space: nowrap;
            cursor: pointer;

            &:hover{
                text-decoration: underline;
            }
        }
    }

    .date {
        min-width: 150px;
        width: 150px;
        text-align: left;
    }
}

.noEquipment {
    padding: 24px 0;
    font-size: .85rem;
    text-align: center;
    color: var(--xlight-text-color);
    border-bottom: 1px dotted #bdbdbd;
}

.footer {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.85rem;
    color: var(--secondary-theme-color-light);
    cursor: pointer;

    & > p:hover {
        color: var(--secondary-theme-color);
        text-decoration: underline;
    }
}
