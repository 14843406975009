.container {
    width: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    gap: 4px;
    //background: var(--secondary-background-color);
    //border-radius: 8px;
    //box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.06);
    position: relative;
    padding: 8px;
    color: var(--medium-text-color);
    overflow: hidden;
    border-bottom: 1px solid rgba(0,0,0,0.1);

    &:first-child{
        border-top: 1px solid rgba(0,0,0,0.1);
    }

    .label {
        font-weight: bold;
        color: var(--medium-text-color);
        font-size: 0.85rem;
        overflow: hidden;
        flex: 1;
    }

    .actions {
        display: flex;
        gap: 12px;
        font-size: 0.85rem;
        color: var(--xlight-text-color);
        cursor: pointer;

        & > * :hover {
            color: var(--medium-text-color);
        }
    }
}
