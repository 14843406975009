.container {
    width: 100%;
    position: relative;

    .input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 4px 10px;
        min-height: 32px;
        color: var(--light-text-color);
        font-size: 13px;
        width: 100%;
        resize: none;
        display: block;
    }

    input:disabled {
        border: none;
        background: transparent;
    }

    textarea.input {
        padding: 8px 10px;
    }

    .input:focus,
    textarea:focus {
        outline: 1px solid rgba(0, 0, 0, 0.35);
    }

    textarea {
        vertical-align: top;
    }

    .readonly {
        cursor: default;
        pointer-events: none;
        color: var(--xlight-text-color);
        user-select: none;

        &:focus {
            outline: none;
        }
    }
}

.iconInput {
    .icon {
        min-height: 32px;
        min-width: 30px;
        max-height: 32px;
        max-width: 30px;
        position: absolute;
        color: var(--xlight-text-color);
        pointer-events: none;

        &::before {
            width: 1px;
            display: block;
            content: '';
            background: rgba(0, 0, 0, 0.15);
            height: 50%;
            position: absolute;
            right: 2px;
            top: 25%;
        }
    }

    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .input {
        padding-left: 34px;
    }
}
