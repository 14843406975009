.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .section {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0;

        .card {
            margin: 0px;
            box-shadow: none;
        }

        .userCard {
            display: flex;
            flex-direction: column;
            height: 233px;
            background: white;

            .userDropdownContainer {
                border-bottom: 2px solid rgba(255, 255, 255, 0.1);
                border-radius: 0;

                .userDropdownLabel {
                    color: var(--medium-text-color);

                    .userDropdownPlaceholder {
                        color: var(--light-text-color);
                    }
                }

                .userDropdownToggle {
                    color: var(--light-text-color);
                }
            }

            .header {
                background: linear-gradient(
                    96.25deg,
                    var(--secondary-theme-color) 16.4%,
                    var(--secondary-theme-color-light) 206.8%
                );
                height: 100px;
                color: var(--contrast-dark-text-color);
                display: flex;
                align-items: center;
                padding: 10px 20px;
                justify-content: space-between;

                .imageContainer {
                    .image {
                        height: 80px;
                        width: 80px;
                        border-radius: 50%;
                    }
                }

                .userContainer {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    text-align: end;

                    .name {
                        font-weight: 700;
                        font-size: 1.1rem;
                        margin-bottom: 3px;
                    }

                    .title,
                    .division,
                    .id {
                        font-size: 0.8rem;
                        color: var(--contrast-light-text-color);
                        font-weight: 500;
                    }
                }
            }

            .userInfo {
                color: var(--medium-text-color);
                padding: 34px 24px;
                font-size: 0.9rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex: 1;

                .infoSection {
                    display: flex;
                    justify-content: space-between;

                    .info {
                        .infoLabel {
                            font-weight: 700;
                            color: var(--medium-text-color);
                        }

                        .infoValue {
                            font-size: 0.8rem;
                            color: var(--light-text-color);
                        }
                    }
                }
            }
        }

        .userJobs {
            background: white;
            flex: 1;
            min-height: 460px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            .actionContainer {
                display: flex;
                flex-direction: row;
                padding: 2px 0;
            }

            .filterContainer {
                min-height: unset;
            }

            .gridContent {
                min-height: unset;
                flex: 1;
                border-top: 1px solid #dde2eb;
            }

            .gridContainer {
                height: 100%;
            }
        }
    }

    .permissions {
        background: white;
        display: flex;
        flex-direction: column;
        flex: 1;
        flex-basis: 0;
        box-shadow: none;
        z-index: 2;

        .hierarchyContainer {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            overflow: auto;
            flex: 1;
        }

        .permissionActions {
            padding: 20px 20px;
            display: flex;
            gap: 8px;
            justify-content: flex-end;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}

.userSearchContainer {
    width: 100%;
    height: 100%;
    padding: 80px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .selectUserGroup {
        width: 100%;

        .userSelectMenu {
            width: 100%;
        }

        .selectUserMessage {
            margin: 8px 0 0 8px;
            font-size: 0.8rem;
            color: var(--light-text-color);
        }
    }
}

@media screen and (min-width: 600px) {
    .userSearchContainer {
        padding: 120px 20px;
        .selectUserGroup {
            width: 500px;
        }
    }
}

@media screen and (min-width: 950px) {
    .container {
        .section {
            flex-direction: row;
            padding: 10px;

            .card {
                margin: 10px;
                box-shadow: 2px 2px 12px 4px rgba(0, 0, 0, 0.05);
            }
        }

        .permissions {
            box-shadow: -1px -1px 12px 4px rgba(0, 0, 0, 0.05);
        }
    }
}

@media screen and (min-width: 1050px) {
    .container {
        flex-direction: row;

        .section {
            flex-direction: column;
            padding: 20px 40px;

            .card {
                margin: 10px 20px;
            }

            .hierarchyContainer {
                flex-wrap: nowrap;
                flex-direction: column;
            }
        }
    }
}

@media screen and (min-width: 1350px) {
    .container {
        .permissions {
            flex: 2;
            .hierarchyContainer {
                flex-wrap: wrap;
                flex-direction: row;
            }
        }
    }
}
