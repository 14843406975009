.form {
    padding: 0 16px;

    .row {
        padding: 12px 0;

        .bubbleContainer {
            display: flex;
            flex-wrap: wrap;

            .bubble {
                margin: 8px 8px 0 0;
                display: flex;
                align-items: center;
                line-height: 0;
                padding: 6px 12px;
                background: var(--primary-theme-color);
                color: var(--contrast-dark-text-color);
                font-weight: bold;
                font-size: .7rem;
                border-radius: 16px;
                height: 24px;
            }
        }

        .emptyMessageContainer{
            font-size: .75rem;
            color: var(--light-text-color);
            padding: 4px 0;
        }
    
        .actionContainer {
            color: var(--xlight-text-color);
            font-size: 0.8rem;
            margin-top: 4px;
    
            & > * {
                display: inline-flex;
                cursor: pointer;
                padding: 4px 6px;
                border-radius: 8px;
    
                &:hover {
                    color: var(--medium-text-color);
                    background: #f1f1f1;
                }
    
                & > :first-child{
                    margin-right: 4px;
                    color: var(--primary-theme-color-light);
                }
            }
        }
    }
}