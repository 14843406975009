.container {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.02);
    width: 100%;

    .headerControls {
        display: flex;
        padding: 3px 0;
        width: 100%;
        background: #ffffff;
        justify-content: space-between;

        .left,
        .right {
            display: flex;
            & > *:not(:first-child) {
                .action {
                    margin: 0 3px;
                }
            }

            & .action {
                background: #ffffff;
                padding: 6px 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.7rem;
                color: var(--light-text-color);
                cursor: pointer;

                &:hover {
                    background: rgba(0, 0, 0, 0.04);
                }
            }

            .active {
                background: rgba(0, 0, 0, 0.08);
                border-radius: 2px;
                color: var(--primary-theme-color);
            }
        }
    }

    .messageContainer {
        .message {
            font-size: 11px;
            padding: 6px 8px;
            display: inline-flex;
            align-items: center;
            margin-top: 4px;
            background: #f9d9d9;
            color: #d91726;

            .icon {
                margin-right: 6px;
            }

            .label {
                margin-top: 3px;
            }
        }
    }

    .drawableArea {
        position: relative;
        width: 100%;
        .pad {
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: white;
            position: relative;
            margin: 0;
            padding: 0;

            &.error {
                border: 1px solid #d91726;
            }
        }

        .drawPrompt {
            color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 28%;
            padding: 0 20px;
            left: 20px;
            width: 100%;
            text-align: center;
            padding-top: 2px;
            border-top: 1px solid rgba(0, 0, 0, 0.15);
            pointer-events: none;

            svg {
                position: absolute;
                top: -100%;
                left: 1px;
                color: rgba(0, 0, 0, 0.15);
            }
        }

        /* &::before{
            z-index: 100;
            position: absolute;
            display: block;
            content: "x";
            font-size: 1.1rem;
            color: rgba(0,0,0,0.3);
            left: 6%;
            bottom: 40%;
        }

        &::after {
            z-index: 100;
            width: 90%;
            padding-top: 2px;
            color: rgba(0,0,0,0.3);
            border-top: 1px solid rgba(0,0,0,0.1);
            display: block;
            content: "Sign Here";
            text-align: center;
            position: absolute;
            bottom: 28%;
            left: 5%;
            pointer-events: none;
        } */
    }

    .toolbar {
        display: flex;

        & > * {
            flex: 1;
        }

        .clear {
            color: white;
            padding: 10px;
        }

        .confirm {
            color: white;
            padding: 10px;
        }
    }
}
