.container {
    h3 {
        margin: 0;
    }

    .noStepsMessage {
        color: var(--xlight-text-color);
        font-style: italic;
        text-align: center;
        margin: 20px 0 40px;
    }

    .categoryContainer {
        &:not(:first-child) {
            margin: 24px 0 0;
        }
    }

    .itemsContainer {
        display: flex;
        flex-direction: column;

        .row {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 8px;
            font-size: 0.9rem;
            color: var(--medium-text-color);
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            padding: 12px 0;

            .responsibleParty {
                text-wrap: nowrap;
            }

            .signature {
                width: 100px;
                max-width: 100px;
                min-width: 100px;
            }

            .actions {
                display: flex;
                gap: 8px;
                flex-wrap: wrap;
            }

            .reminder {
                font-size: 0.8rem;
                color: var(--light-text-color);
                margin-right: 4px;
            }
        }
    }

    .responsibleParty {
        flex: 1;
    }
}
