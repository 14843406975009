.form {
    .row {
        padding: 12px 0;
        display: flex;
        gap: 12px;

        & > * {
            flex: 1;
        }

        .hoursWorked {
            width: 130px;
            min-width: 130px;
            max-width: 130px;
        }

        .dateWrapper {
            width: 100%;
            flex: 1;

            & input {
                width: 100%;
            }
        }

        .emptyMessageContainer {
            font-size: 0.75rem;
            color: var(--light-text-color);
            padding: 4px 0;
        }

        .actionContainer {
            color: var(--xlight-text-color);
            font-size: 0.8rem;
            margin-top: 4px;

            & > * {
                display: inline-flex;
                cursor: pointer;
                padding: 4px 6px;
                border-radius: 8px;

                &:hover {
                    color: var(--medium-text-color);
                    background: #f1f1f1;
                }

                & > :first-child {
                    margin-right: 4px;
                    color: var(--primary-theme-color-light);
                }
            }
        }
    }
}
