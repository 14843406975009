.overlayContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .overlayLabel {
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .topLabel {
        flex-direction: column-reverse;

        .label {
            margin-bottom: 8px;
        }
    }

    .bottomLabel {
        flex-direction: column;

        .label {
            margin-top: 8px;
        }
    }

    .leftLabel {
        flex-direction: row-reverse;

        .label {
            margin-right: 8px;
        }
    }

    .rightLabel {
        flex-direction: row;

        .label {
            margin-left: 8px;
        }
    }

    .contrast {
        color: var(--contrast-xlight-text-color);
    }

    .normal {
        color: var(--light-text-color);
    }

    .xl,
    .lg,
    .md,
    .sm,
    .xs {
        font-weight: 500;
    }
}
