.container {
    .card {
        margin: 10px 0;
        padding: 16px;
        display: flex;
        gap: 16px;
        background: rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.01);

        .cardBody {
            display: flex;
            flex-direction: column;
            gap: 12px;
            flex: 1;
            font-size: .85rem;
            justify-content: space-evenly;
            color: var(--medium-text-color);

            .header {
                display: flex;
                align-items: center;
                gap: 4px;
                font-weight: bold;

                .action {
                    cursor: pointer;
                    padding: 2px 4px;
                    svg {
                        font-size: 0.8rem;
                        color: var(--xlight-text-color);
                    }
    
                    &:hover {
                        svg {
                            color: var(--medium-text-color);
                        }
                    }
                }

                .workType {
                    flex: 1;
                }
            }

            .dates {
                display: flex;
                gap: 4px;
                color: var(--light-text-color);

                .dateWrapper {
                    width: 100%;
                    flex: 1;

                    & input {
                        width: 100%;
                    }
                }

                .hours {
                    font-weight: bold;
                }
            }

            .notes {
                font-size: .8rem;
                color: var(--light-text-color);
            }
        }

        .cardActions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .action {
                cursor: pointer;
                padding: 4px;
                svg {
                    font-size: 0.8rem;
                    color: var(--xlight-text-color);
                }

                &:hover {
                    svg {
                        color: var(--medium-text-color);
                    }
                }
            }
        }
    }

    .timeButton {
        width: 100%;
        margin: 0;
    }
}
