.loading {
    display: flex;
    align-items: center;
    color: #7e7e7e;
    font-size: 0.8rem;
    flex: 1;
    width: 100%;
    height: 100%;
}

.loading .loadingContainer {
    width: 28px;
    display: flex;
    justify-content: center;
}

.loading > :not(:first-child) {
    margin-left: 12px;
}
