.react-date-picker {
    display: inline-flex;
    position: relative;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-date-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
}
.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    height: 32px;
    padding: 4px 0;
    font-size: 13px;
    color: var(--light-text-color) !important;
    border-radius: 4px;
}

.react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 6px;
    box-sizing: content-box;
}

.lowPadding .react-date-picker__inputGroup {
    padding: 0 3px;
}

.react-date-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
}
.react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
    color: var(--light-text-color) !important;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
    color: var(--light-text-color);
}
.react-date-picker__button:enabled {
    cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
    stroke: #6d6d6d;
}
.react-date-picker__button svg {
    display: inherit;
}
.react-date-picker__button:hover {
    color: var(--medium-text-color);
}
.react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    box-shadow: 1px 1px 12px 2px rgba(0, 0, 0, 0.05);
}
.react-date-picker__calendar--closed {
    display: none;
}
.react-date-picker__calendar .react-calendar {
    border-width: thin;
}

.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    line-height: 1.125em;
    font-family: 'Roboto' !important;

    & > * {
        font-family: 'Roboto' !important;
    }
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;

    &:not(:first-child) {
        border-left: 1px solid rgba(0, 0, 0, 0.05);
    }
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: rgba(0, 0, 0, 0.05);
}
.react-calendar__navigation button[disabled] {
    background-color: rgba(0, 0, 0, 0.05);
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    & abbr {
        text-decoration: none !important;
    }
}
.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    padding: calc(10px / 0.75) calc(10px / 0.75);
}
.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    border: 0 solid rgba(0, 0, 0, 0.04) !important;
    border-width: 0 1px 1px 0 !important;
    font-size: 13px;
    color: var(--light-text-color);
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: rgba(0, 0, 0, 0.05);
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: var(--xlight-text-color);
}
.react-calendar__tile--now {
    background: var(--secondary-theme-color);
    color: var(--contrast-dark-text-color);
    font-weight: 700;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: var(--secondary-theme-color);
    color: var(--contrast-dark-text-color);
    opacity: 0.9;
}
.react-calendar__tile--hasActive {
    background: var(--primary-theme-color-light);
    color: var(--contrast-dark-text-color);
    font-weight: 700;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: var(--primary-theme-color-light);
}
.react-calendar__tile--active {
    background: var(--primary-theme-color-light);
    color: var(--contrast-dark-text-color);
    font-weight: 700;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--primary-theme-color-light);
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.react-datepicker-wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    height: 32px;
    padding: 4px 0;
    font-size: 13px;
    color: var(--light-text-color) !important;
    border-radius: 4px;
}

.react-datepicker-wrapper input {
    border: none;
    outline: none;
    padding: 0 10px;
}

.react-datepicker__input-container {
    display: flex;
}

.react-datepicker__day--selected,
li.react-datepicker__time-list-item--selected.react-datepicker__time-list-item--selected {
    background: var(--secondary-theme-color-light) !important;

    &:hover {
        background: var(--secondary-theme-color) !important;
    }
}

.react-datepicker__close-icon {
    &::after {
        background-color: var(--secondary-theme-color-light);
    }
    &:hover::after {
        background-color: var(--secondary-theme-color);
    }
}

.react-datepicker__input-container input {
    color: var(--light-text-color);
}
