.permissionSection {
    width: 100%;
    padding: 40px 80px;
    position: relative;

    .header {
        font-weight: 700;
        font-size: 1.2rem;
        margin-bottom: 4px;
        color: var(--dark-text-color);
    }

    .permissionContainer {
        margin-left: 40px;

        .subheader {
            font-weight: 500;
            font-size: 0.95rem;
            margin: 8px 0 4px 0;
            color: var(--medium-text-color);
        }

        .categoryHeader {
            font-size: 0.85rem;
            margin: 8px 0 4px 0;
            color: var(--medium-text-color);
        }

        .category {
            font-size: 0.75rem;
            display: flex;
            margin: 2px 0 4px;
            display: flex;
            align-items: center;
            cursor: pointer;

            & > p {
                margin: 0 0 0 10px;
                white-space: nowrap;
            }
        }
    }
}

.permissionContainer {
    margin-left: 0;
}

@media screen and (min-width: 760px) {
    .permissionSection {
        width: 50%;
    }
}

@media screen and (min-width: 1050px) {
    .permissionSection {
        width: 100%;
    }
}

@media screen and (min-width: 1350px) {
    .permissionSection {
        width: 50%;
    }
}

@media screen and (min-width: 1700px) {
    .permissionSection {
        width: 33.3%;
    }
}
