.tabs {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: 40px;

    .tabContainer {
        display: flex;
        position: relative;
        overflow-y: hidden;
        overflow-x: auto;
        align-items: center;
        height: 100%;
        font-size: 0.95rem;

        hr {
            position: absolute;
            height: 2px;
            background: var(--primary-theme-color-light);
            margin: 0;
            bottom: 0px;
            border: none;
        }

        .container {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 8px;
            color: var(--light-text-color);
            cursor: pointer;
            position: relative;

            &.active {
                color: var(--dark-text-color);

                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: var(--primary-theme-color-light);
                }
            }

            &:hover:not(.active) {
                &::after {
                    display: block;
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: rgba(167, 69, 86, 0.2);
                }
            }
        }
    }
}

.tabGroupContainer {
    .tabListContainer {
        display: flex;
        flex-wrap: wrap;

        .tabContainer {
            cursor: pointer;
            color: var(--light-text-color);
            padding: 10px 10px;
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3px;
            }

            &.selected {
                color: var(--medium-text-color);

                &:after {
                    background: var(--primary-theme-color-light);
                }

                &.secondary:after {
                    background: var(--secondary-theme-color-light);
                }
            }

            &:not(.selected):hover {
                &:after {
                    background: rgba(0, 0, 0, 0.1);
                }
            }

            &:hover {
                color: var(--medium-text-color);
            }
        }
    }
}

.tabGroupStepperContainer {
    .tabListContainer {
        display: flex;
        gap: 3px;

        .tabContainer {
            cursor: pointer;
            color: var(--light-text-color);

            &.disabled {
                cursor: default;
                color: rgba(0, 0, 0, 0.25);
            }

            &.selected {
                cursor: default;
                color: var(--primary-theme-color-light);
                font-weight: bold;
                text-decoration: underline;

                &::after {
                    color: var(--primary-theme-color-light);
                    font-weight: bold;
                }
            }

            &:hover:not(.selected):not(.disabled) {
                color: var(--medium-text-color);
                text-decoration: underline;
            }

            &:not(:last-child)::after {
                content: '>>';
                display: inline-block;
                color: rgba(0, 0, 0, 0.35);
                padding-left: 5px;
                font-size: 0.8rem;
                flex: 1;
            }
        }
    }
}
