.container {
    height: 100%;
    width: 100%;
    position: relative;

    .customizePromptContainer {
        background: white;
        padding: 20px;

        .header {
            margin-bottom: 10px;
            color: var(--medium-text-color);
            font-weight: 500;
        }

        .inputs {
            display: flex;
            flex-direction: column;

            & > * {
                margin: 10px 0;
            }
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;

            & > * {
                margin: 0;
            }
        }
    }
}
