.activityContainer {
    background: white;
    padding: 30px 30px 10px 30px;
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-left: 4px solid var(--secondary-theme-color-light);
    position: relative;

    .activityLock {
        position: absolute;
        top: 12px;
        left: 12px;
        color: var(--light-text-color);
    }

    .activityRemoveContainer {
        position: absolute;
        top: 8px;
        right: 8px;

        .activityRemoval {
            padding: 6px;
            opacity: 0.9;
            cursor: pointer;
            color: var(--light-text-color);
            font-size: 0.65rem;
            text-transform: uppercase;
            font-weight: 600;

            &:hover {
                opacity: 1;
                color: var(--primary-theme-color-light);
            }
        }
    }

    .activityHeader {
        margin: 6px 0;
    }

    .activityBody {
        & > * {
            &:first-child {
                margin: 6px 0;
            }

            margin: 14px 0;
        }

        .locationOfWork {
            width: 400px;
        }

        .hazardContainer,
        .employeeContainer {
            .hazardAddContainer,
            .employeeAddContainer {
                display: flex;
                margin-bottom: 8px;

                align-items: center;

                h5 {
                    margin-top: 2px;
                }

                .hazardCertificationWarning {
                    display: flex;
                    align-items: center;
                    font-size: 0.8rem;
                    margin: 2px 6px 6px;

                    .icon {
                        color: #d40000;
                        margin-right: 6px;
                    }

                    .message {
                        .context {
                            color: var(--light-text-color);
                            margin-right: 4px;
                            display: inline;
                        }

                        .details {
                            display: inline-block;
                            color: var(--primary-theme-color);
                            opacity: 1;
                            font-size: 0.72rem;

                            .missingCertContainer {
                                width: 300px;
                                height: 340px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                position: relative;

                                .close {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    cursor: pointer;
                                    opacity: 0.9;
                                    position: absolute;
                                    top: 6px;
                                    right: 6px;
                                    color: var(--contrast-dark-text-color);
                                    padding: 3px 4px;
                                    font-size: 0.85rem;

                                    &:hover {
                                        opacity: 1;
                                    }
                                }

                                .header {
                                    display: flex;
                                    align-items: center;
                                    font-size: 1rem;
                                    font-weight: 500;
                                    height: 40px;
                                    padding: 10px 10px;
                                    margin: 0;
                                    background: rgb(96, 0, 17);
                                    color: var(--contrast-medium-text-color);

                                    svg {
                                        font-size: 0.75rem;
                                    }

                                    p {
                                        margin-left: 8px;
                                        margin-top: 1px;
                                    }
                                }

                                .missingCerts {
                                    display: flex;
                                    flex-direction: column;
                                    overflow: auto;
                                    overscroll-behavior: contain;
                                    height: 300px;

                                    .missingCert {
                                        display: flex;
                                        flex-direction: column;
                                        margin-bottom: -1px;

                                        .cert {
                                            display: flex;
                                            align-items: center;
                                            padding: 4px 12px 0;
                                            margin: 0;
                                            font-size: 0.9rem;
                                            color: var(--medium-text-color);
                                            border-bottom: 1px solid
                                                rgba(0, 0, 0, 0.08);
                                            border-top: 1px solid
                                                rgba(0, 0, 0, 0.08);
                                            height: 30px;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            background: rgba(0, 0, 0, 0.02);
                                        }

                                        .employee {
                                            padding: 6px 16px;
                                            color: var(--light-text-color);

                                            &:not(:last-child) {
                                                border-bottom: 1px solid
                                                    rgba(0, 0, 0, 0.08);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .addActionContainer {
                    display: flex;
                    padding: 6px 8px;
                    cursor: pointer;
                    border-radius: 50%;
                    opacity: 0.6;

                    svg {
                        color: #0c9b00;
                    }

                    &:hover {
                        opacity: 1;
                        background: rgba(0, 0, 0, 0.04);
                    }
                }
            }

            .employeeAddContainer {
                flex-direction: column;
                align-items: flex-start;

                h5 {
                    margin-bottom: 8px;
                }

                .employeeSelect {
                    width: 400px;
                    margin-bottom: 8px;
                }

                .employeeList {
                    display: flex;
                    flex-wrap: wrap;

                    & > * {
                        margin: 4px;
                    }
                }
            }

            .hazardDropdown {
                display: inline-block;
                margin-bottom: 14px;
                width: 400px;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            .hazardItems {
                display: flex;
                flex-wrap: wrap;
                min-height: 38px;

                .noHazards {
                    font-size: 0.7rem;
                    color: var(--light-text-color);
                    margin-left: 10px;
                }

                .item {
                    display: inline-block;
                    margin-bottom: 10px;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }

        .hazardAddContainer,
        .employeeAddContainer {
            display: flex;

            & > *:first-child {
                margin-right: 8px;
            }
        }

        .timestamp {
            display: flex;
            justify-content: flex-end;
            color: var(--light-text-color);
            font-size: 0.7rem;
        }
    }
}
