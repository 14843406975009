.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 120px;
        height: 120px;

        .loaderCircle {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            box-shadow: inset 0 0 0 1px rgba(121, 35, 48, 0.1);
            margin-left: -60px;
            margin-top: -60px;
        }

        .loaderLineMask {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 60px;
            height: 120px;
            margin-left: -60px;
            margin-top: -60px;
            overflow: hidden;
            transform-origin: 60px 60px;
            -webkit-mask-image: -webkit-linear-gradient(
                top,
                rgba(121, 35, 48, 1),
                rgba(121, 35, 48, 0)
            );
            animation: rotate 1.2s infinite linear;
        }

        .loaderLine {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            box-shadow: inset 0 0 0 1px rgba(121, 35, 48, 0.5);
        }
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
