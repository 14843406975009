.container {
    display: flex;
    align-items: center;

    .primaryCheckboxContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        width: 28px;
        color: var(--xlight-text-color);
        opacity: 0.75;
        cursor: pointer;
        margin-right: 4px;

        &.active {
            color: var(--primary-theme-color-light);
            opacity: 1;
        }

        &:hover {
            color: var(--primary-theme-color-light);
            opacity:  0.85;
        }
    }

    .textInputContainer {
        flex: 1;

        .textInput {
            border: none !important;
            border-radius: 0 !important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
            background: transparent;

            &:focus,
            &:active {
                outline: none;
            }
        }
    }

    .clearIcon {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        color: var(--xlight-text-color);
        font-size: .9rem;
        cursor: pointer;
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-left: 8px;

        &:hover{
            color: var(--primary-theme-color-light);
        }
    }
}
