.toggle {
    border-radius: 10px;
    width: 40px;
    height: 20px;
    position: relative;
    overflow: hidden;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.1s linear;

    &:after {
        position: absolute;
        display: block;
        content: '';
        background: #ffffff;
        border-radius: 50%;
        height: 15px;
        top: 3px;
        width: 15px;
        box-shadow: 2px 2px 12px 4px rgba(0, 0, 0, 0.1);
        transition: all 0.1s linear;
    }
}

.toggle.on {
    background: var(--primary-theme-color-light);
    &:after {
        left: calc(100% - 18px);
    }
}

.toggle.off {
    background: #e2e2e2;
    &:after {
        left: 3px;
    }
}
