.errorContainer {
    height: 100%;
    min-height: 100%;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    overflow: auto;

    .error {
        font-size: 7rem;
        font-weight: bold;
        color: var(--xlight-text-color);
        margin-bottom: 40px;
    }

    .header {
        color: var(--medium-text-color);
        font-weight: 600;
        margin-bottom: 30px;
        font-size: 1.2rem;
    }

    .message {
        color: var(--light-text-color);
        font-size: 0.9rem;
        margin-bottom: 10px;
    }

    .link {
        a,
        a:visited {
            color: var(--primary-theme-color);
            text-decoration: none;
            opacity: 0.7;
        }

        a:hover {
            opacity: 1;
        }

        svg {
            margin-right: 8px;
        }
    }
}

.container {
    .link {
        margin-bottom: 20px;
        a,
        a:visited {
            color: var(--primary-theme-color);
            text-decoration: none;
            opacity: 0.7;
        }

        a:hover {
            opacity: 1;
        }

        svg {
            margin-right: 8px;
        }
    }

    .pretaskContainer {
        font-size: 0.85rem;
        background: #ffffff;
        box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        border-left: 1px solid rgba(0, 0, 0, 0.12);

        h1 {
            color: var(--light-text-color);
        }

        h3 {
            color: var(--medium-text-color);
        }

        h5 {
            color: var(--light-text-color);
            font-size: 1rem;
            margin: 0;
            padding: 0;
        }

        .section {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            .header {
                border-bottom: 1px solid rgba(0, 0, 0, 0.02);
                padding: 14px 0 14px 10px;
                background: #e5e5e5;

                h1,
                h3 {
                    margin: 0;
                }
            }

            .body {
                padding: 16px;

                & > * {
                    margin: 10px 0;
                }

                .checklists {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 30px;

                    .checklistItem {
                        flex-basis: 50%;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        padding: 10px;
                        text-align: center;
                        margin-top: -1px;
                        font-size: 0.85rem;
                        background: white;
                        box-shadow: 1px 1px 12px 2px rgba(0, 0, 0, 0.02);
                    }
                }

                &.signatures {
                    display: flex;
                    flex-direction: column;

                    h3 {
                        margin: 8px 0;
                    }

                    .requiredSignatures {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap-reverse;

                        .employeeSignatures {
                            flex: 2;
                            min-width: 340px;
                            margin-right: 40px;

                            .noEmployeeMessage {
                                color: var(--light-text-color);
                                font-size: 0.8rem;
                                margin-left: 2px;
                            }
                        }

                        .supervisorSignatures {
                            flex: 2;
                            min-width: 340px;
                            margin-bottom: 18px;

                            .signatureAddContainer {
                                align-items: flex-start;

                                .noEmployeeMessage {
                                    color: var(--light-text-color);
                                    font-size: 0.8rem;
                                    margin-left: 2px;
                                }
                            }

                            @media screen and (min-width: 894px) {
                                .signatureAddContainer {
                                    align-items: flex-end;
                                }
                            }
                        }
                    }

                    .extraSignatures {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .signatureList {
                            margin-bottom: 6px;
                        }

                        .noExtraMessage {
                            color: var(--light-text-color);
                            font-size: 0.85rem;
                            margin-left: 2px;
                        }
                    }
                }
            }

            .input {
                page-break-inside: avoid;
            }

            .input,
            .hazardList,
            .employees {
                padding: 10px;

                .label {
                    color: var(--medium-text-color);
                    font-weight: 600;
                    font-size: 0.95rem;
                }

                .value {
                    margin: 3px 0 0 3px;
                    color: var(--light-text-color);
                    font-size: 0.85rem;
                }

                .notProvided {
                    margin: 3px 0 0 3px;
                    color: var(--xlight-text-color);
                    font-size: 0.85rem;
                }
            }

            .activity {
                padding: 24px 16px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                .input:first-child {
                    margin-top: 0;
                }

                .hazards,
                .employeeList {
                    display: block;
                    margin-top: 4px;

                    & > * {
                        display: inline-block;
                        margin-bottom: 5px;
                        margin-top: 5px;
                        page-break-inside: avoid;

                        &:not(:last-child) {
                            margin-right: 10px;
                        }
                    }

                    .card {
                        background: rgb(228, 227, 227) !important;
                        border: 1px solid rgb(228, 227, 227) !important;
                        box-shadow: none !important;
                        color: var(--light-text-color) !important;
                    }
                }

                .timestamp {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 0.75rem;
                    color: var(--light-text-color);
                }
            }
        }

        .pretaskHeader {
            .section {
                margin-top: 0;
                border-top: none;

                .header {
                    padding: 20px;
                    background: #e5e5e5;

                    h1,
                    h3 {
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 750px) {
    .container {
        margin: 20px 40px;
        .section {
            .body {
                padding: 10px 30px 10px 30px;
                margin: 10px 0;
            }

            .activity {
                padding: 30px;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    .container {
        margin: 20px auto;

        .link {
            margin-left: 10px;
        }

        .section {
            .body {
                margin: 10px 0;

                .checklists {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-bottom: 30px;

                    .checklistItem {
                        page-break-inside: avoid;
                        &:nth-child(even) {
                            margin-left: 30px;
                            flex-basis: calc(50% - 30px);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .container {
        width: 80%;
        max-width: 1100px;
    }
}

@media screen and (min-width: 1500px) {
    .container {
        width: 60%;
        margin: 40px auto;
    }
}

@media print {
    .link {
        display: none;
    }
}
