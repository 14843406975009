.signatureContainer {
    height: 50px;
    width: 240px;
    border-bottom: 1px solid rgba(0,0,0,0.6);
    padding: 8px;

    p {
        background: lightgray;
        border-radius: 4px;
        height: 34px;
        width: 60px;
        display: inline-flex;
        align-items: center;
        padding: 0 0 0 8px;
        cursor: pointer;

        svg {
            font-size: 1.4rem;
        }
    }

    .emptySignature.disabled {

        p {
            opacity: 0.2;
            cursor: unset;
        }
    }
}

.signature {
    max-width: 240px;
    height: 48px;
}

.signerName {
    padding: 2px 0;
    font-weight: bold;
    color: var(--medium-text-color);
    font-size: .8rem;
}

.statusContainer {
    width: 240px;
    margin: 8px 0;

    .submittedStatus {
        font-size: .85rem;
        text-align: center;
        color: var(--light-text-color);
    }
}