.dismiss {
    color: var(--xlight-text-color) !important;

    &:hover {
        color: var(--light-text-color) !important;
    }
}

.container {
    .header {
        min-height: 40px;
        display: flex;
        align-items: center;
        padding: 10px 40px 10px 10px;
        font-size: 0.9rem;
        color: var(--light-text-color);
        font-weight: 600;

        .headerLabel {
            width: 100%;
        }
    }

    .actions {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .save,
        .cancel {
            padding: 6px 12px;
            font-size: 0.8rem;
            border-radius: 4px;
            color: var(--contrast-dark-text-color);
            font-weight: 600;
            box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.06);
            cursor: pointer;
            opacity: 0.9;

            &:hover {
                opacity: 1;
            }
        }

        .cancel {
            background: #d40000;
        }

        .save {
            background: #0c9b00;
            display: flex;

            p {
                margin-left: 6px;
            }
        }
    }
}
