.documentContainer {
    margin: 20px 6px;

    .header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding: 8px 0;
        font-size: 1.4rem;
        margin: 0;
    }

    .document,
    .loading {
        min-width: 33.333%;
        padding: 16px 8px;
        display: flex;
    }

    .document {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .body {
            display: flex;
            align-items: center;
            flex: 1;

            .upper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1;

                .name {
                    color: var(--medium-text-color);
                    margin-right: 15px;
                    width: 100%;
                    font-size: 0.95rem;
                }

                .modified {
                    font-size: 0.7rem;
                    color: var(--light-text-color);
                    margin-top: 2px;
                }
            }

            .thumbnail {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 8px;
                flex-basis: 0;
                min-width: 24px;

                .image {
                    width: 100%;
                    max-height: 24px;
                }

                .icon {
                    color: var(--light-text-color);
                    font-size: 22px;
                }
            }
        }

        .options {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: flex-end;
            font-size: 0.8rem;

            p:not(.notAvailable) {
                color: var(--secondary-theme-color);
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            .downloading {
                display: flex;

                .loader {
                    margin: 0 4px;
                }
            }

            .notAvailable {
                color: var(--light-text-color);
                font-size: 0.7rem;
            }

            & > p {
                margin: 0 4px;
            }
        }

        p {
            display: inline-block;
        }
    }
}

@media screen and (min-width: 800px) {
    .documentContainer {
        margin: 40px 80px;
        width: 80%;
    }
}

@media screen and (min-width: 1200px) {
    .documentContainer {
        margin: 60px 120px;
        width: 70%;
    }
}

@media screen and (min-width: 1400px) {
    .documentContainer {
        margin: 60px 120px;
        width: 60%;
    }
}
