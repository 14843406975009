.buttonContainer {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: var(--light-text-color);
    font-size: 13px;
    cursor: pointer;
    width: 100%;
    height: 32px;
    user-select: none;
    overflow: hidden;
    position: relative;

    &:focus-within {
        outline: 1px solid rgba(0, 0, 0, 0.35);
    }

    &.toggled {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .loader {
        padding: 0 0 0 4px;
    }

    .placeholder {
        flex-grow: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 10px;
        margin-bottom: -3px;
        color: #a9a9a9;
    }

    .selected {
        flex-grow: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 10px;
        height: 100%;
        color: var(--light-text-color);
        outline: none;
        border: none;
        background: transparent;
    }

    .activeIcon {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--xlight-text-color);
        font-size: 10px;
        padding: 0 6px;
        position: absolute;
        right: 0;
    }

    .activeIcon.readonly {
        color: var(--xlight-text-color) !important;
        cursor: default;
    }

    &:hover .activeIcon,
    .toggled {
        color: var(--medium-text-color);
    }

    &.readonly {
        cursor: default;
        opacity: 0.5;
    }
}

.contentContainer {
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 12px;
    color: var(--light-text-color);
    border-radius: 4px;
    max-height: 300px;
    overflow: auto;

    .option {
        padding: 0 4px;
        cursor: pointer;
        position: relative;
        transition: padding 0.15s linear;
        height: 30px;
        display: flex;
        align-items: center;

        &.noRecords {
            margin-left: 6px;
            color: var(--light-text-color);
        }

        &:hover:not(.active) {
            background: rgba(0, 0, 0, 0.04);
        }

        &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .activeIndicator {
            padding: 0 4px;
            width: 20px;
            min-width: 20px;
            max-width: 20px;
            display: inline-block;
            color: var(--primary-theme-color-light);
        }

        p {
            margin-top: 2px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.loader {
            display: flex;
            align-items: center;

            .loader {
                margin: 0 6px;
            }

            p {
                margin-top: 2px;
            }
        }
    }

    .active {
        background: var(--primary-theme-color-light);
        color: var(--contrast-medium-text-color);
        font-weight: 600;
    }
}
