.container {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--secondary-background-color);
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    /* padding: 12px; */
    color: var(--light-text-color);
    font-size: 0.9rem;
    margin-bottom: 12px;
    overflow: hidden;

    .dragHandle {
        background: var(--primary-theme-color-light);
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--xlight-text-color);
        font-size: 1.1rem;

        &:hover {
            color: var(--dark-text-color);
        }
    }

    .index {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        font-weight: bold;
        background: rgba(0, 0, 0, 0.2);
        color: var(--medium-text-color);
    }

    .body {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 8px;
        padding: 10px;

        .header {
            display: flex;
            font-weight: bold;
            gap: 8px;

            svg {
                font-size: 0.8rem;
            }
        }

        .descriptionContainer {
            display: flex;
            flex-direction: column;
            gap: 8px;

            & > * {
                border-top: 1px solid rgba(0, 0, 0, 0.2);
                padding: 8px 0 0;
            }

            @media only screen and (min-width: 675px) {
                flex-direction: row;
                gap: 0;
            }

            .description {
                font-size: 0.85rem;
                flex: 1;

                h4 {
                    margin: 0 0 3px;
                }
            }
        }

        .attachmentContainer {
            border-top: 1px solid rgba(0, 0, 0, 0.2);
        }

        .commentsContainer {
            display: flex;
            flex-direction: column;
            gap: 8px;

            & > * {
                border-top: 1px solid rgba(0, 0, 0, 0.2);
                padding: 8px 0 0;
            }
        }

        .timestampContainer {
            display: flex;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            padding: 8px 0 0;

            & div {
                flex: 1;
            }
        }
    }

    .actions {
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: center;
        font-size: 0.85rem;
        color: var(--xlight-text-color);
        background: rgba(0, 0, 0, 0.15);
        padding: 12px 18px 12px 24px;

        svg {
            padding: 0 1px;
        }

        & > :hover {
            color: var(--dark-text-color);
            cursor: pointer;
        }
    }

    .dragContainer {
        display: flex;
        align-items: center;
        margin-left: 0x;

        span {
            padding: 4px;
            border-radius: 4px;

            &:hover {
                background: rgba(0, 0, 0, 0.075);
            }
        }
    }
}
