.bookmarkContainer {
    display: flex;
    overflow: auto;
    align-items: flex-start;
    flex: 1;
    height: 100%;

    .bookmarkPanelContainer {
        padding: 20px 40px;

        @media screen and (max-width: 1150px) {
            padding: 20px 10px;
        }
    }

    .bookmarkListContainer {
        position: sticky;
        top: 0;
        left: 0;
        height: 100%;
        min-width: 200px;
        background: var(--secondary-background-color);
        box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;

        .bookmarkListInnerContainer {
            flex: 1;
            overflow: auto;
            padding: 0 24px;

            .bookmarkSectionContainer {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding: 16px 0;

                .optionContainer {
                    text-align: right;
                    color: var(--light-text-color);
                    font-size: 0.9rem;
                    position: relative;
                    cursor: pointer;

                    &:hover {
                        color: var(--dark-text-color);
                    }

                    &:hover .option:not(.selected) {
                        .selectedIndicator {
                            background: rgba(0, 0, 0, 0.1);
                        }
                    }

                    .option {
                        display: inline-block;
                        position: relative;
                        padding: 8px;

                        .selectedIndicator {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            height: 3px;
                            width: 100%;
                        }

                        &.selected .selectedIndicator {
                            background: var(--primary-theme-color-light);
                        }
                    }
                }
            }
        }

        .bookmarkListFooterContainer {
            display: flex;
            flex-direction: column;
            padding: 16px 24px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            .linkContainer {
                color: var(--light-text-color);
                font-size: 0.9rem;
                position: relative;
                text-align: right;
                display: flex;
                align-items: center;
                padding: 8px;
                cursor: pointer;

                &:hover {
                    color: var(--primary-theme-color);
                }

                &.selected .selectedIndicator {
                    background: var(--primary-theme-color-light);
                }

                &:not(.selected):hover {
                    .selectedIndicator {
                        background: rgba(0, 0, 0, 0.1);
                    }
                }

                .link {
                    display: inline-block;
                    position: relative;
                    flex: 1;
                }
            }
        }
    }

    .overlayListContainer {
        .overlayButton {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 20px;
            color: rgba(255, 255, 255, 0.9);
            right: 30px;
            width: min-content;
            padding: 0 16px;
            gap: 8px;
            font-weight: bold;
            font-size: 0.85rem;
            z-index: 100;
            height: 40px;
            border-radius: 12px;
            background: var(--secondary-theme-color-light);
            overflow: hidden;
            box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            opacity: 0.9;

            &:hover {
                opacity: 1;
                color: rgba(255, 255, 255, 1);
            }

            p {
                color: rgba(255, 255, 255, 0.9);
            }
        }
    }
}

.overlayListModalContent {
    width: 300px;
    max-width: 300px;
    .overlayListInnerContainer {
        .bookmarkListInnerContainer {
            flex: 1;
            overflow: auto;

            .bookmarkSectionContainer {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding: 16px 0;

                &:first-child {
                    padding-top: 0;
                }

                .optionContainer {
                    text-align: center;
                    color: var(--light-text-color);
                    font-size: 0.9rem;
                    position: relative;
                    cursor: pointer;

                    &:hover {
                        color: var(--dark-text-color);
                    }

                    &:hover .option:not(.selected) {
                        .selectedIndicator {
                            background: rgba(0, 0, 0, 0.1);
                        }
                    }

                    .option {
                        display: inline-block;
                        position: relative;
                        padding: 8px;

                        .selectedIndicator {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            height: 3px;
                            width: 100%;
                        }

                        &.selected .selectedIndicator {
                            background: var(--primary-theme-color-light);
                        }
                    }
                }
            }
        }

        .bookmarkListFooterContainer {
            display: flex;
            flex-direction: column;
            padding: 16px 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            .linkContainer {
                color: var(--light-text-color);
                font-size: 0.9rem;
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 16px;
                padding: 8px;
                cursor: pointer;

                &:hover {
                    color: var(--primary-theme-color);
                }

                &.selected .selectedIndicator {
                    background: var(--primary-theme-color-light);
                }

                &:not(.selected):hover {
                    .selectedIndicator {
                        background: rgba(0, 0, 0, 0.1);
                    }
                }

                .link {
                    display: inline-block;
                    position: relative;
                }
            }
        }
    }
}
