.noItems {
    position: absolute;
    top: 60px;
    left: 50%;
    max-width: 80%;
    width: 80%;
    text-align: center;
    line-height: 1.1rem;
    z-index: 1;
    transform: translate(-50%, -50%);
    font-size: 0.75rem;
    padding: 10px 20px;
    color: var(--light-text-color);
    font-weight: 500;
}
