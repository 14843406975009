.confirmationMessage {
    color: var(--dark-text-color);
    margin-bottom: 24px;
}

.detailMessage {
    color: var(--medium-text-color);
    font-size: 0.85rem;
    line-height: 1.1rem;
    margin-bottom: 24px;
}
