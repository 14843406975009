.removeLine {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 4px;
    cursor: pointer;
    color: var(--light-text-color);
    opacity: .75;

    .removeIcon {
        font-size: .75rem;
    }

    &:hover {
        opacity: 1;
        color: var(--medium-text-color);
    }
}