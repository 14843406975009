.container {
    height: 100%;
    min-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .noPermissions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        color: var(--light-text-color);
        text-align: center;

        .changeJob {
            margin-top: 40px;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap-reverse;
        padding: 0 0 0 16px;
        background: var(--secondary-background-color);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .currentJob, .userPermissions {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--light-text-color);
            font-size: 0.85rem;
            height: 40px;
            white-space: nowrap;
            overflow: hidden;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            padding: 0 16px;
            max-width: 300px;

            .jobDescriptionContainer {
                overflow: hidden;
                flex: 1;

                .jobDescription {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-left: 4px;
                }
            }

            .editJob {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                width: 20px;
                color: var(--xlight-text-color);
                cursor: pointer;

                &:hover {
                    color: var(--primary-theme-color-light);
                }
            }
        }
    
        .rightOptions {
            display: flex;
            align-items: center;
        }
    }
}
